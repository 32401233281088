import { ApolloError } from '@apollo/client';
import { IEndpointVariables, useGetSkuGraphql } from 'api/endpoints/sku/get';
import { getErrorTransform } from 'api/transforms/errors';
import SkuTransform, { IserviceResponseSku } from 'api/transforms/SkuTransform';

interface IServiceState {
  loading: boolean;
  error: ApolloError | undefined | string;
  response: IserviceResponseSku | undefined;
  refetch: () => void;
}

const getSku = (endpointParams: IEndpointVariables): IServiceState => {
  const { loading, error, data, refetch } = useGetSkuGraphql(endpointParams);

  if (data) {
    return {
      loading,
      error,
      response: SkuTransform(data),
      refetch,
    };
  } else {
    return {
      loading,
      error: getErrorTransform(error),
      response: data,
      refetch,
    };
  }
};

export default getSku;
