import React from 'react';
import usePostUpdateProductPrice from 'api/services/activeListings/post';
import toast from 'react-hot-toast';
import Alert from '@material-ui/lab/Alert';
import usePostUpdateProductPriceChildren from 'api/services/activeListings/postUpdateChildren';
import { Button, CircularProgress, TextField } from '@material-ui/core';
import { ButtonEdit } from 'modules/amazonAccounts/styles';

interface IPropsPrice {
  type: string;
  asin?: string | undefined;
  fnsku?: string | undefined;
  price: number | undefined;
  productId?: string | undefined;
  productMaxPrice?: number | undefined;
  productMinPrice?: number | undefined;
  page: number | undefined;
  rowsPerPage: number | undefined;
  dataCollapse?: any;
  refetch: () => void;
}

const UpdatePrice: React.FC<IPropsPrice> = ({
  asin,
  fnsku,
  type,
  price,
  productId,
  productMaxPrice,
  productMinPrice,
  dataCollapse,
  refetch,
}) => {
  const [editPrice, setEditPrice] = React.useState(false);
  const [newPrice, setNewPrice] = React.useState<any>(price);
  const rowHasChildren = type === 'parent' && dataCollapse?.length !== 0;

  const { ordersActiveListingPriceParent, loading, data, error } =
    usePostUpdateProductPrice();

  const {
    ordersActiveListingPriceChildren,
    data: dataChildren,
    loading: loadingChildren,
  } = usePostUpdateProductPriceChildren();

  const savePrice = () => {
    ordersActiveListingPriceParent({
      variables: {
        asin,
        fnsku,
        price: newPrice,
      },
    });
    setEditPrice(false);
  };

  const savePriceChildren = () => {
    if (productMinPrice && productMaxPrice) {
      if (newPrice >= productMinPrice && newPrice <= productMaxPrice) {
        ordersActiveListingPriceChildren({
          variables: {
            productId,
            price: newPrice,
          },
        });
        setEditPrice(false);
      } else {
        toast.error(
          `Price must be between ${productMinPrice} and ${productMaxPrice}`,
        );
      }
    }
  };

  React.useEffect(() => {
    if (data?.status || dataChildren?.status) {
      toast.success('Item price has been updated');
      refetch();
    }
  }, [data?.status, dataChildren?.status]);

  const handleChange = (event: any) => {
    setNewPrice(event.target.value);
  };

  if (error)
    return (
      <Alert data-testid='alertErrorService' severity='error'>
        {error}
      </Alert>
    );

  return (
    <>
      {loading || loadingChildren ? (
        <CircularProgress />
      ) : !editPrice ? (
        <Button
          data-testid={
            type === 'parent' ? 'updatePrice' : 'updatePriceChildren'
          }
          disabled={rowHasChildren ? true : false}
          onClick={() => setEditPrice(true)}
          variant='contained'
          color='primary'>
          Update Price
        </Button>
      ) : (
        <>
          <TextField
            value={newPrice}
            type='number'
            onChange={handleChange}
            data-testid='newPriceField'
            id='newPrice'
            label='Price'
          />
          {type === 'parent' ? (
            <ButtonEdit
              data-testid='saveButton'
              variant='contained'
              color='primary'
              onClick={savePrice}>
              Save
            </ButtonEdit>
          ) : (
            type === 'children' && (
              <ButtonEdit
                data-testid='saveButtonChildren'
                variant='contained'
                color='primary'
                onClick={savePriceChildren}>
                Save
              </ButtonEdit>
            )
          )}
          <Button
            data-testid='cancelButton'
            onClick={() => setEditPrice(false)}>
            Cancel
          </Button>
        </>
      )}
    </>
  );
};

export default UpdatePrice;
