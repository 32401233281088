import { gql } from '@apollo/client';

export const GET_ACCOUNTS = gql`
  query getAcounts($userId: String!) {
    accountsByUser(userId: $userId) {
      data {
        _id
        mwsName
        mwsSellerId
        mwsStatus
      }
      status
    }
  }
`;

export const POST_AMAZON_ACCOUNT = gql`
  mutation PostAmazonAccount(
    $mwsSellerId: String!
    $spapiOauthCode: String!
    $user: String!
  ) {
    createMwsAccount(
      input: {
        mwsSellerId: $mwsSellerId
        spapiOauthCode: $spapiOauthCode
        user: $user
      }
    ) {
      status
      message
    }
  }
`;

export const POST_UPDATE_MWS_ACCOUNT = gql`
  mutation postUpdateMwsAccount(
    $accountId: String!
    $userId: String!
    $updateData: MwsAccountUpdateDataInput!
  ) {
    updateMwsAccount(
      accountId: $accountId
      userId: $userId
      updateData: $updateData
    ) {
      status
      data {
        mwsName
        mwsStatus
      }
    }
  }
`;
