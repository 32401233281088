import { ApolloError, useQuery } from '@apollo/client';
import { IAmazonAccountsExternal } from 'interfaces/AmazonAccounts';
import { GET_ACCOUNTS } from './queries';

interface IEndPointResponse {
  loading: boolean;
  error: ApolloError | undefined;
  data: IAmazonAccountsExternal | undefined;
  refetch: () => void;
}

export interface IEndpointVariables {
  userId: string | null;
}

export const useGetConectAmazonAccountsGraphql = ({
  userId,
}: IEndpointVariables): IEndPointResponse => {
  const { loading, error, data, refetch } = useQuery<
    IAmazonAccountsExternal,
    IEndpointVariables
  >(GET_ACCOUNTS, {
    variables: {
      userId,
    },
  });

  return {
    loading,
    error,
    data,
    refetch,
  };
};
