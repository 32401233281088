import React from 'react';
import usePostUpdateMwsAccount from 'api/services/amazonAccounts/postUpdateMwsAccount';
import useAuthContext from '@base/common/hooks/auth-context';
import toast from 'react-hot-toast';
import getConectAccounts from 'api/services/amazonAccounts/get';
import { Button, CircularProgress, TextField } from '@material-ui/core';
import { ButtonEdit } from '../styles';

interface IFieldProps {
  field: string | undefined;
  id: string;
}

const EditName: React.FC<IFieldProps> = ({ field, id }) => {
  const { userId } = useAuthContext();
  const { updateMwsAccount, loading, data } = usePostUpdateMwsAccount();
  const { refetch } = getConectAccounts({ userId });
  const [editName, setEditName] = React.useState(false);
  const [newName, setNewName] = React.useState(field);

  const saveName = () => {
    updateMwsAccount({
      variables: {
        accountId: id,
        userId,
        updateData: { mwsName: newName },
      },
    });
    setEditName(false);
  };

  React.useEffect(() => {
    if (data?.status) {
      toast.success('The Market place Name has been updated');
      refetch();
    }
  }, [data?.status]);

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : !editName ? (
        <>
          {field}
          <ButtonEdit
            data-testid='editButton'
            variant='contained'
            color='primary'
            onClick={() => setEditName(true)}>
            Edit
          </ButtonEdit>
        </>
      ) : (
            <>
              <TextField
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
                data-testid='nameField'
                id='Name'
                label='Name'
              />
              <ButtonEdit
                variant='contained'
                color='primary'
                onClick={saveName}
                data-testid='saveButton'>
                Save
          </ButtonEdit>
              <Button onClick={() => setEditName(false)} data-testid='cancelButton'>
                Cancel
          </Button>
            </>
          )}
    </>
  );
};

export default EditName;
