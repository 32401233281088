import React from 'react';
import FilterSection from 'components/UI/filters/FilterSection';
import useAuthContext from '@base/common/hooks/auth-context';
import useGetSales from 'api/services/sales/get';
import Alert from '@material-ui/lab/Alert';
import DataTableTest from 'components/UI/DataTable/containers/dataTableTest';
import useOptionsPage from 'hooks/useOptionsPage';
import {
  dataCollapseSales,
  propertyTitleSales,
  titleCollapseSales,
} from '../constants/index';
import { useLocation } from 'react-router-dom';
import {
  dataHeaders,
  dataTableFields,
  inputsFilters,
  selectFilters,
  dataFieldsCollapseSales,
  dataTableRowsPerPage,
  dateFilters,
} from '../constants/index';

interface IFilterState {
  AmazonOrderId: string;
  dateStart: string | undefined;
  dateEnd: string | undefined;
  InternalId: string;
  FulfillmentChannel: string;
  OrderStatus: string | unknown;
}

const SalesList = () => {
  const { userId } = useAuthContext();
  const { page, setPage, rowsPerPage, setRowsPerPage, validatePage } =
    useOptionsPage();

  const { state } = useLocation<any>();

  const [filterOptions, setFilterOptions] = React.useState<IFilterState>({
    AmazonOrderId: '',
    dateStart: state ? state?.firstDay : undefined,
    dateEnd: state ? state?.lastDay : undefined,
    InternalId: '',
    FulfillmentChannel: '',
    OrderStatus: '',
  });
  const [filterComplete, setFilterComplete] =
    React.useState<IFilterState>(filterOptions);

  const { loading, error, response } = useGetSales({
    userId,
    reqPage: page + 1,
    reqLimit: rowsPerPage,
    filters: filterComplete,
  });

  React.useEffect(() => {
    setRowsPerPage(dataTableRowsPerPage);
  }, []);

  if (error)
    return (
      <Alert data-testid='alertErrorService' severity='error'>
        {error}
      </Alert>
    );

  return (
    <>
      <FilterSection
        setFilterOptions={setFilterOptions}
        filterOptions={filterOptions}
        setFilterComplete={setFilterComplete}
        inputsFilters={inputsFilters}
        selectFilters={selectFilters}
        dateFilters={dateFilters}
        isValidateDate
        showFilters={state?.filterOn}
        isTrasformDate={state?.transformDate}
        setPage={setPage}
      />
      <div className='mt-10'>
        <DataTableTest data={response?.data as any[]} loading={loading}>
          <DataTableTest.Container>
            <DataTableTest.Headers headers={dataHeaders} sortBy={'orderId'} />
            <DataTableTest.body>
              <DataTableTest.Row
                dataFields={dataTableFields}
                dataFieldsCollapse={dataFieldsCollapseSales}
                dataCollapse={dataCollapseSales}
                titleCollapse={titleCollapseSales}
                propertyTitle={propertyTitleSales}
              />
            </DataTableTest.body>
          </DataTableTest.Container>
          <DataTableTest.Pagination
            count={response?.meta.totalDocs || 0}
            page={validatePage(loading, page)}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
          <DataTableTest.DensenPadding />
        </DataTableTest>
      </div>
    </>
  );
};

export default SalesList;
