import { IGetOrdersOffersData } from 'interfaces/ActiveListings';
import { IOrdersGetOffers } from '../../interfaces/ActiveListings';

export const getOrdersOffersTransformLazy = (
  data: IGetOrdersOffersData,
): IOrdersGetOffers => {
  const dataObject = data.ordersGetOffers.data;
  const dataStatus = data.ordersGetOffers.status;

  return { status: dataStatus, data: dataObject };
};
