import React from 'react';
import { Typography } from '@material-ui/core';
import EmailSettings from './containers';

const EmailSettingsModule = () => {
  return (
    <div data-testid='emailSettingsPage' className='bg-white p-7'>
      <div className='flex items-center'>
        <Typography variant='h1' component='h2' gutterBottom>
          Email Settings
        </Typography>
      </div>
      <EmailSettings />
    </div>
  );
};

export default EmailSettingsModule;
