import React from 'react';
import useUrlParams from 'hooks/useUrlParams';
import { usePostAmazonAccountGraphql } from 'api/endpoints/amazonAccounts/post';
import {
  amazonRedirectSPAPI,
  amazonRedirectSellingPartnerId,
  amazonRedirectState,
} from 'modules/amazonAccounts/constants/index';
import { MutationHookOptions } from '@apollo/client/react/types/types';
import { OperationVariables } from '@apollo/client/core/types';

interface IServiceState {
  loading: boolean;
}

const usePostAmazonAccount = (
  options: MutationHookOptions<any, OperationVariables> | undefined,
): IServiceState => {
  const { postAmazonAccount, loading } = usePostAmazonAccountGraphql(options);
  const params = useUrlParams();

  React.useEffect(() => {
    const isAbleToSave =
      params.has(amazonRedirectSPAPI) &&
      params.has(amazonRedirectState) &&
      params.has(amazonRedirectSellingPartnerId);

    if (isAbleToSave) {
      postAmazonAccount({
        variables: {
          spapiOauthCode: params.get(amazonRedirectSPAPI) || '',
          mwsSellerId: params.get(amazonRedirectSellingPartnerId) || '',
          user: params.get(amazonRedirectState) || '',
        },
      });
    }
  }, []);

  return { loading };
};

export default usePostAmazonAccount;
