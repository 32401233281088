// TODO: refactor type any to date
const useDateFormat = (date: any) => {
  if (date) {
    const dateToFormat = new Date(date);
    const newDate = new Intl.DateTimeFormat('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZone: 'UTC',
    }).format(dateToFormat);

    return `${newDate} UTC`;
  } else {
    return '0000-00-00T00:00:00.000Z';
  }
};

export default useDateFormat;
