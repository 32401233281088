/* eslint-disable react/display-name */
import React from 'react';
import { useLocalStorage } from 'react-use';

export const dashboardSteps = [
  {
    selector: '.tour1',
    content: () => (
      <p>
        Welcome to
        <span className='text-blue-700 font-semibold text-lg'>
          {' '}
          Returns application guided tour.
        </span>
        This tour will help you to have a better understanding of our platform.
      </p>
    ),
  },
  {
    selector: '.tour2',
    content: () => (
      <p>
        Please verify your account in order to access the full features of our
        app.
      </p>
    ),
  },
  {
    selector: '.tour3',
    content: () => (
      <p>
        To start working with your orders, please connect your Amazon account.
      </p>
    ),
  },
  {
    selector: '.tour4',
    content: () => <p>Here you can link/unlink multiple Amazon accounts.</p>,
  },
  {
    selector: '.tour4',
    content: () => (
      <img src='/images/tutorial/tour4.png' alt='button amazon account' />
    ),
  },
  {
    selector: '.tour5',
    content: () => (
      <p>
        Once you have connected your Amazon account(s), here you can see all the
        removal orders imported from your accounts.
      </p>
    ),
  },
  {
    selector: '.tour5',
    content: () => (
      <>
        <p>
          If you have any doubt about a module, you can see in the blue icon the
          details of it.
        </p>
        <img src='/images/tutorial/tour5.png' alt='Removal orders help' />
      </>
    ),
  },
  {
    selector: '.tour5',
    content: () => (
      <>
        <p>
          It is really important that you confirm or discard the removal orders
          that are going to be sent to our return locations, so we know what
          boxes need to be processed.
        </p>
        <p className='text-red-700'>
          If no orders are confirmed to be sent, our agents in the return
          location will not process any of your boxes.
        </p>
        <img src='/images/tutorial/tour51.png' alt='List accounts' />
      </>
    ),
  },
  {
    selector: '.tour1',
    content: () => (
      <div>
        <p>
          In these sections you will find the details and information of your
          account, membership and connected Amazon accounts.
        </p>
        <img width='175' src='/images/tutorial/tour6.png' alt='Sections menu' />
      </div>
    ),
  },
  {
    selector: '.tour1',
    content: () => (
      <div>
        <p>
          In these sections you can check all regarding the inventory you have
          sent to our returns locations.
        </p>
        <img width='175' src='/images/tutorial/tour7.png' alt='Sections menu' />
      </div>
    ),
  },
  {
    selector: '.tour1',
    content: () => (
      <div>
        <p>
          In these sections you will find the details and statuses of your items
          that are listed or have been sold in our sales channels.
        </p>
        <img width='175' src='/images/tutorial/tour8.png' alt='Sections menu' />
      </div>
    ),
  },
  {
    selector: '.tour1',
    content: () => (
      <div>
        <p>
          You will find there all regarding your payment methods and payment
          summary.
        </p>
        <img width='175' src='/images/tutorial/tour9.png' alt='Sections menu' />
      </div>
    ),
  },
  {
    selector: '.tour1',
    content: ({ setIsOpen }) => {
      const [, , dontShowAgain] = useLocalStorage('show-tutorial');

      const handleDontShowAgain = () => {
        dontShowAgain();
        setIsOpen(false);
      };
      return (
        <div>
          <p>Tour finished.</p>
          <button
            className='bg-blue-700 text-white px-3 py-2'
            onClick={handleDontShowAgain}>
            Don’t show this again
          </button>
        </div>
      );
    },
  },
];

export const stylesOverlay = {
  close: (base) => ({
    ...base,
    top: 5,
    right: 5,
    height: '15px',
    width: '15px',
  }),
};

export const badgeContent = ({ totalSteps, currentStep }) =>
  `${Number(currentStep) + 1}/${totalSteps}`;
