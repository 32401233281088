interface IStatus {
  pending: string;
  'partial received': string;
  received: string;
  shipped: string;
  cancelled: string;
  completed: string;
  canceled: string;
  'over received': string;
}

export const status: IStatus = {
  pending: 'pending',
  'partial received': 'partial received',
  received: 'received',
  shipped: 'shipped',
  cancelled: 'cancelled',
  canceled: 'canceled',
  completed: 'completed',
  'over received': 'over received',
};

export const textTooltipOrderStatus = {
  pending: 'Amazon still processiong the removal order.',
  partialShipped: 'Some units have alredy been shipped.',
  shipped: 'All the items of the order have been shipped',
  cancelled: 'Order has been canceled on Amazon',
  completed: 'Order fully shipped by Amazon',
};

export const textTooltipInternalStatus = {
  pending: 'Order not yet received in our warehouse.',
  shipped: 'All quantities ordered from Amazon were shipped.',
  cancelled: 'Delivery request from Amazon has been canceled.',
  partialReceived:
    'Some items of the order have been received in our warehouse.',
  completed: 'Confirmed receipt of items.',
  received: 'The order has been received enterely in our warehouse.',
  overReceived: 'A higher than expected quantity of products was received.',
};

export const dataTableFields = [
  {
    field: 'orderId',
    headerName: 'Amazon Removal ID',
    sort: false,
  },
  {
    field: 'orderStatus',
    withChip: true,
    headerName: 'Amazon Removal Status',
    sort: false,
    textTooltip: textTooltipOrderStatus,
    ColumnTooltip: 'Display the current status of the removal order on Amazon.',
  },
  {
    field: 'internalStatus',
    withChip: true,
    headerName: 'Warehouse Order Status',
    sort: false,
    textTooltip: textTooltipInternalStatus,
    ColumnTooltip:
      'Display the current status of the order depending wether any items have been or not received in our warehouse.',
  },
  {
    field: 'createdAt',
    headerName: 'Removal Creation Date',
    isDate: true,
    sort: false,
  },
  {
    field: 'detail',
    headerName: 'Detail',
    sort: false,
  },
  {
    field: '',
    headerName: 'Confirmed Status',
    isButton: true,
    sort: false,
    ColumnTooltip:
      'It tells you if you have already confirmed or not to send the removal order to our warehouse.',
  },
];

export const dataFieldsCollapse = [
  {
    field: 'asin',
    headerName: 'ASIN',
  },
  {
    field: 'carrier',
    headerName: 'Carrier',
  },
  {
    field: 'fnsku',
    headerName: 'FNSKU',
  },
  {
    field: 'itemName',
    headerName: 'Item Name',
  },
  {
    field: 'sku',
    headerName: 'SKU',
  },
  {
    field: 'trackingNumber',
    headerName: 'Tracking Number',
    isTrackingCollapse: true,
  },
  {
    field: 'inprocessQty',
    headerName: 'In-Process Qty',
    ColumnTooltip: 'Amazon in-process QTY.',
  },
  {
    field: 'shippedQty',
    headerName: 'Shipped Qty',
    ColumnTooltip: 'Units already shipped by Amazon.',
  },
  {
    field: 'cancelledQty',
    headerName: 'Cancelled Qty',
    ColumnTooltip: 'Units canceled by Amazon and not included in the removal.',
  },
  {
    field: 'requestedQty',
    headerName: 'Total Requested Qty',
    ColumnTooltip: 'Total units requested for the removal.',
  },
];

export const dataHeaders = [
  {
    field: 'items',
    headerName: 'Items',
    sort: false,
  },
  ...dataTableFields,
];

export const inputsFilters = [
  { id: 'orderId', name: 'orderId', label: '	Amazon Removal ID' },
  {
    id: 'trackingNumber',
    name: 'trackingNumber',
    label: 'Tracking Number',
    isMultipleValueInput: true,
  },
];

export const selectFilters = [
  {
    name: 'orderStatus',
    menuItem: [
      {
        value: '',
        labelName: 'Amazon Removal Status',
        disabled: true,
      },
      {
        value: 'pending',
        labelName: 'Pending',
        disabled: false,
      },
      {
        value: 'shipped',
        labelName: 'Shipped',
      },
      {
        value: 'received',
        labelName: 'Received',
      },
      {
        value: 'cancelled',
        labelName: 'Cancelled',
        disabled: false,
      },
      {
        value: 'refunded',
        labelName: 'Refunded',
      },
    ],
  },
  {
    name: 'internalStatus',
    menuItem: [
      {
        value: '',
        labelName: 'Warehouse Order Status',
        disabled: true,
      },
      {
        value: 'pending',
        labelName: 'Pending',
      },
      {
        value: 'shipped',
        labelName: 'Shipped',
        disabled: false,
      },
      {
        value: 'received',
        labelName: 'Received',
      },
      {
        value: 'partial received',
        labelName: 'Partial Received',
        disabled: false,
      },
      {
        value: 'cancelled',
        labelName: 'Cancelled',
      },
      {
        value: 'refunded',
        labelName: 'Refunded',
      },
    ],
  },
  {
    name: 'toProcess',
    menuItem: [
      {
        value: '',
        labelName: 'Confirmed Status',
        disabled: true,
      },
      {
        value: 'accept',
        labelName: 'Send to our warehouse',
      },
      {
        value: 'discard',
        labelName: 'Discard Order',
      },
    ],
  },
  {
    name: 'statusAccount',
    menuItem: [
      {
        value: '',
        labelName: 'Status Account',
      },
      {
        value: 'active',
        labelName: 'Active',
      },
      {
        value: 'inactive',
        labelName: 'Inactive',
      },
    ],
  },
];

export const dateFilters = [
  {
    id: 'dateStart',
    name: 'dateStart',
    label: 'Start Date',
  },
  {
    id: 'dateEnd',
    name: 'dateEnd',
    label: 'End Date',
  },
];

export const dataCollapseRemoval = 'items';
export const titleCollapseRemoval = 'Items- Order ID';
export const propertyTitleRemoval = 'orderId';

export const dataTableRowsPerPage = 10;
