import {
  IAcceptanceExternal,
  IAcceptanceInternal,
} from 'interfaces/Acceptance';

export interface IServiceResponse {
  meta: {
    totalDocs: number;
    totalPages: number;
  };
  data: IAcceptanceInternal[];
}

export const getAcceptanceTransform = (
  data: IAcceptanceExternal,
): IServiceResponse => {
  const newData = data.acceptanceCustomers.data;

  const meta = {
    totalDocs: data.acceptanceCustomers.totalDocs,
    totalPages: data.acceptanceCustomers.totalPages,
  };

  return { meta, data: newData };
};
