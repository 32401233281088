import { ApolloError, useQuery } from '@apollo/client';
import { ISalesExternal } from 'interfaces/Sales';
import { GET_SALES } from './queries';

interface IEndpointResponse {
  loading: boolean;
  error: ApolloError | undefined;
  data: ISalesExternal | undefined;
}

export interface ISalesFilters {
  AmazonOrderId?: string;
  PurchaseDate?: string | null | undefined;
  InternalId?: string;
  FulfillmentChannel?: string;
  OrderStatus?: string | unknown;
}

export interface ISalesVars {
  userId: string | null;
  reqPage: number;
  reqLimit: number;
  filters: ISalesFilters;
}

export const useGetSalesGraphql = ({
  userId,
  reqPage,
  reqLimit,
  filters,
}: ISalesVars): IEndpointResponse => {
  const { loading, error, data } = useQuery<ISalesExternal, ISalesVars>(
    GET_SALES,
    {
      variables: {
        userId,
        reqPage,
        reqLimit,
        filters,
      },
    },
  );

  return {
    loading,
    error,
    data,
  };
};
