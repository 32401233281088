import React from 'react';
import useAuthContext from '@base/common/hooks/auth-context';
import getConectAccounts from 'api/services/amazonAccounts/get';
import toast from 'react-hot-toast';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import usePostUpdateMwsAccount from 'api/services/amazonAccounts/postUpdateMwsAccount';
import { Button, CircularProgress } from '@material-ui/core';
import { UnlinkButton } from '../styles';

interface IUpdateProps {
  accountId: string;
  accountStatus: string;
}

const ModifyMwsStatus: React.FC<IUpdateProps> = ({
  accountId,
  accountStatus,
}) => {
  const { userId } = useAuthContext();
  const { refetch } = getConectAccounts({ userId });
  const { updateMwsAccount, loading, data } = usePostUpdateMwsAccount();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const unlinkAccount = () => {
    updateMwsAccount({
      variables: {
        accountId,
        userId,
        updateData: { mwsStatus: 'inactive' },
      },
    });
    handleClose();
  };

  const linkAccount = () => {
    updateMwsAccount({
      variables: {
        accountId,
        userId,
        updateData: { mwsStatus: 'active' },
      },
    });
    handleClose();
  };

  React.useEffect(() => {
    if (data?.status) {
      toast.success(
        `The ${data.data?.mwsName} account is now ${data.data?.mwsStatus}.`,
      );
      refetch();
    }
  }, [data?.status]);

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : accountStatus === 'active' ? (
        <UnlinkButton
          data-testid='unlinkButton'
          onClick={handleClickOpen}
          variant='contained'>
          Unlink
        </UnlinkButton>
      ) : (
        accountStatus === 'inactive' && (
          <Button
            data-testid='linkButton'
            onClick={handleClickOpen}
            variant='contained'
            color='primary'>
            Link
          </Button>
        )
      )}
      <div>
        <Dialog
          data-testid='confirmModal'
          open={open}
          onClose={handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'>
          <DialogTitle id='alert-dialog-title'>{'Are you sure?'}</DialogTitle>
          <DialogContent>
            {accountStatus === 'active' ? (
              <DialogContentText id='alert-dialog-description'>
                The selected account will become inactive
              </DialogContentText>
            ) : (
              accountStatus === 'inactive' && (
                <DialogContentText id='alert-dialog-description'>
                  The selected account will be activated
                </DialogContentText>
              )
            )}
          </DialogContent>
          <DialogActions>
            <Button color='primary' onClick={handleClose}>
              Cancel
            </Button>
            {accountStatus === 'active' ? (
              <UnlinkButton
                data-testid='confirmUnlinkButton'
                onClick={unlinkAccount}>
                Unlink
              </UnlinkButton>
            ) : (
              accountStatus === 'inactive' && (
                <Button
                  variant='contained'
                  color='primary'
                  data-testid='confirmLinkButton'
                  onClick={linkAccount}>
                  Link
                </Button>
              )
            )}
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default ModifyMwsStatus;
