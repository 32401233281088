import { ApolloError, useQuery } from '@apollo/client';
import { GET_FINANCE } from './queries';
import { IFinances } from 'interfaces/Finances';

interface IEndpointResponse {
  loading: boolean;
  error: ApolloError | undefined;
  data: IFinances | undefined;
  refetch: () => void;
}

export interface IFinancesVars {
  userId: string;
  month: number;
  year: number;
}

export const useGetFinancesGraphql = ({
  userId,
  month,
  year,
}: IFinancesVars): IEndpointResponse => {
  const { loading, error, data, refetch } = useQuery<IFinances, IFinancesVars>(
    GET_FINANCE,
    {
      variables: {
        userId,
        month,
        year,
      },
    },
  );

  return {
    loading,
    error,
    data,
    refetch,
  };
};
