import React from 'react';
import AmazonAccountsContainer from './containers';

const AmazonAccounts: React.FC = () => {
  return (
    <div data-testid='amazonAccountsPage' className='bg-white p-5'>
      <AmazonAccountsContainer />
    </div>
  );
};

export default AmazonAccounts;
