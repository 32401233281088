import React from 'react';
import getSku from '../../../api/services/sku/get';
import Alert from '@material-ui/lab/Alert';
import useAuthContext from '@base/common/hooks/auth-context';
import FilterSection from 'components/UI/filters/FilterSection';
import DataTableTest from 'components/UI/DataTable/containers/dataTableTest';
import useOptionsPage from 'hooks/useOptionsPage';
import {
  dataTableSkusFields,
  dataTableRowsPerPage,
  inputsFilters,
} from '../constants';

const SkuList: React.FC = () => {
  const { userId } = useAuthContext();
  const { page, setPage, rowsPerPage, setRowsPerPage, validatePage } =
    useOptionsPage();

  const [filterOptions, setFilterOptions] = React.useState({
    sku: '',
    asin: '',
    fnsku: '',
    name: '',
  });
  const [filterComplete, setFilterComplete] = React.useState(filterOptions);

  const { loading, error, response } = getSku({
    userId,
    reqPage: page + 1,
    reqLimit: rowsPerPage,
    filters: filterComplete,
  });

  React.useEffect(() => {
    setRowsPerPage(dataTableRowsPerPage);
  }, []);

  if (error)
    return (
      <Alert data-testid='alertErrorService' severity='error'>
        {error}
      </Alert>
    );

  return (
    <>
      <FilterSection
        selectFilters={[]}
        inputsFilters={inputsFilters}
        setFilterOptions={setFilterOptions}
        filterOptions={filterOptions}
        setFilterComplete={setFilterComplete}
        setPage={setPage}
      />
      <div className='mt-10'>
        <DataTableTest data={response?.data as any[]} loading={loading}>
          <DataTableTest.Container>
            <DataTableTest.Headers
              headers={dataTableSkusFields}
              sortBy={'orderId'}
            />
            <DataTableTest.body>
              <DataTableTest.Row dataFields={dataTableSkusFields} />
            </DataTableTest.body>
          </DataTableTest.Container>
          <DataTableTest.Pagination
            count={response?.meta.totalDocs || 0}
            page={validatePage(loading, page)}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
          <DataTableTest.DensenPadding />
        </DataTableTest>
      </div>
    </>
  );
};

export default SkuList;
