/* eslint-disable react/display-name */
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import useClientGrapql from 'config/graphql/client';
import { TourProvider } from '@reactour/tour';
import { ApolloProvider } from '@apollo/client';
import {
  badgeContent,
  dashboardSteps,
  stylesOverlay,
} from 'config/tutorial/steps';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import './index.css';

const Main = () => {
  const client = useClientGrapql();

  return (
    <ApolloProvider client={client}>
      <TourProvider
        steps={dashboardSteps}
        styles={stylesOverlay}
        badgeContent={badgeContent}>
        <App />
      </TourProvider>
    </ApolloProvider>
  );
};

ReactDOM.render(<Main />, document.getElementById('root'));

serviceWorkerRegistration.register();

reportWebVitals();
