/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react';
import { Button, TextField } from '@material-ui/core';
import { ButtonEdit } from 'modules/amazonAccounts/styles';
import usePostUpdateProductPriceChildren from '../../../api/services/activeListings/postUpdateChildren';
import toast from 'react-hot-toast';
import useStatesActiveLinstings from 'hooks/useStateActiveListing';

import Popover from '@material-ui/core/Popover';
import useGetOrdersOffersLazy from 'api/services/activeListings/getordersOffersLazy';

interface IMinMaxPriceCustomer {
  min: number | undefined;
  max: number | undefined;
  promedio: number | undefined;
  id: string | undefined;
}

export const ControllerUpdatePrice = ({ productId, asin, condition }: any) => {
  const { setRefetchActiveListings } = useStatesActiveLinstings();

  const [updatePrice, setUpdatePrice] = useState(false);

  const [dataItem, setDataItem] = useState({
    asin: '',
    condition: '',
  });

  const [minMaxPriceCustomer, setMinMaxPriceCustomer] =
    useState<IMinMaxPriceCustomer>({
      min: 0,
      max: 0,
      promedio: 0,
      id: '',
    });

  const { getOrdersOffers, loading, serviceResponse } =
    useGetOrdersOffersLazy();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    asinItem: any,
    conditionItem: any,
  ) => {
    setAnchorEl(event.currentTarget);
    setDataItem({
      asin: asinItem,
      condition: conditionItem,
    });
    setUpdatePrice(true);
  };

  useEffect(() => {
    if (dataItem.asin !== '') {
      getOrdersOffers({
        variables: {
          asin: dataItem.asin,
          channel: 'amazon',
          itemCondition: dataItem.condition,
        },
      });
    }
  }, [dataItem.asin, dataItem.condition]);

  const handleClose = () => {
    setAnchorEl(null);

    setUpdatePrice(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const { ordersActiveListingPriceChildren } =
    usePostUpdateProductPriceChildren({
      onCompleted(dataResponse: any) {
        toast.success(dataResponse.ordersActiveListingPriceChildren.data);
        setUpdatePrice(false);
        setMinMaxPriceCustomer({ min: 0, max: 0, promedio: 0, id: '' });
        setRefetchActiveListings(true);
      },
    });

  const savePrice = () => {
    ordersActiveListingPriceChildren({
      variables: {
        productId,
        price: minMaxPriceCustomer.promedio,
        productCustomerMaxPrice: minMaxPriceCustomer.max,
        productCustomerMinPrice: minMaxPriceCustomer.min,
      },
    });
  };

  useEffect(() => {
    if (
      minMaxPriceCustomer.min !== 0 &&
      minMaxPriceCustomer.max !== 0 &&
      updatePrice
    ) {
      const promedio =
        (Number(minMaxPriceCustomer.min) + Number(minMaxPriceCustomer.max)) / 2;
      setMinMaxPriceCustomer({
        ...minMaxPriceCustomer,
        promedio,
        id: productId,
      });
    }
  }, [minMaxPriceCustomer.min, minMaxPriceCustomer.max]);

  useEffect(() => {
    if (serviceResponse) {
      setMinMaxPriceCustomer({
        ...minMaxPriceCustomer,
        min: serviceResponse.data.minPrice,
        max: serviceResponse.data.maxPrice,
      });
    }
  }, [serviceResponse?.data]);

  return (
    <>
      {updatePrice && loading && <p>loading...</p>}

      <Button
        aria-describedby={id}
        onClick={(e) => {
          handleClick(e, asin, condition);
        }}
        variant='contained'
        color='primary'>
        Update Price
      </Button>

      {updatePrice && !loading && (
        <>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}>
            <div className='p-5'>
              <div>
                <p className='font-bold capitalize'>
                  min Price: ${serviceResponse?.data?.minPrice}
                </p>
                <TextField
                  error={
                    minMaxPriceCustomer.min === undefined ||
                    minMaxPriceCustomer.min === 0 ||
                    minMaxPriceCustomer.min < serviceResponse!.data.minPrice ||
                    minMaxPriceCustomer.min > serviceResponse!.data.maxPrice
                  }
                  placeholder={serviceResponse?.data.minPrice.toString()}
                  defaultValue={serviceResponse?.data?.minPrice}
                  type='number'
                  onBlurCapture={() => {
                    if (minMaxPriceCustomer.min === 0) {
                      setMinMaxPriceCustomer({
                        ...minMaxPriceCustomer,
                        min: serviceResponse?.data?.minPrice,
                      });
                    }
                  }}
                  onChange={(e) =>
                    setMinMaxPriceCustomer({
                      ...minMaxPriceCustomer,
                      min: Number(e.target.value),
                    })
                  }
                  data-testid='newPriceField'
                  id='newPrice'
                />
              </div>
              <div className='mt-4 mb-4'>
                <p className='font-bold capitalize'>
                  max price: ${serviceResponse?.data.maxPrice}{' '}
                </p>
                <TextField
                  error={
                    minMaxPriceCustomer.max === undefined ||
                    minMaxPriceCustomer.max === 0 ||
                    minMaxPriceCustomer.max < serviceResponse!.data.minPrice ||
                    minMaxPriceCustomer.max > serviceResponse!.data.maxPrice
                  }
                  defaultValue={serviceResponse?.data.maxPrice}
                  placeholder={serviceResponse?.data.maxPrice.toString()}
                  type='number'
                  onBlurCapture={() => {
                    if (
                      minMaxPriceCustomer.max === 0 ||
                      minMaxPriceCustomer.max === undefined
                    ) {
                      setMinMaxPriceCustomer({
                        ...minMaxPriceCustomer,
                        max: serviceResponse?.data.maxPrice,
                      });
                    }
                  }}
                  onChange={(e) =>
                    setMinMaxPriceCustomer({
                      ...minMaxPriceCustomer,
                      max: Number(e.target.value),
                    })
                  }
                  data-testid='newPriceField'
                  id='newPrice'
                />
              </div>

              <ButtonEdit
                disabled={
                  minMaxPriceCustomer.min === undefined ||
                  minMaxPriceCustomer.max === undefined ||
                  minMaxPriceCustomer.min === 0 ||
                  minMaxPriceCustomer.max === 0 ||
                  minMaxPriceCustomer.min < serviceResponse!.data.minPrice ||
                  minMaxPriceCustomer.min > serviceResponse!.data.maxPrice ||
                  minMaxPriceCustomer.max > serviceResponse!.data.maxPrice ||
                  minMaxPriceCustomer.max < serviceResponse!.data.minPrice
                }
                data-testid='saveButtonChildren'
                variant='contained'
                color='primary'
                onClick={savePrice}>
                Save
              </ButtonEdit>
              <Button data-testid='cancelButton' onClick={handleClose}>
                Cancel
              </Button>
            </div>
          </Popover>
        </>
      )}
    </>
  );
};
