import { ApolloError, useQuery } from '@apollo/client';
import { IOrderDetailExternal } from 'interfaces/OrderDetail';
import { GET_ORDER_DETAIL } from './queries';

interface IEndpointResponse {
  loading: boolean;
  error: ApolloError | undefined;
  data: IOrderDetailExternal | undefined;
}

export interface IOrderDetailVars {
  orderId: string;
  userId: string | null;
}

export const useGetOrderDetailGraphql = ({
  orderId,
  userId,
}: IOrderDetailVars): IEndpointResponse => {
  const { loading, error, data } = useQuery<
    IOrderDetailExternal,
    IOrderDetailVars
  >(GET_ORDER_DETAIL, {
    variables: {
      orderId,
      userId,
    },
  });

  return {
    loading,
    error,
    data,
  };
};
