import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }),
);

export type Order = 'asc' | 'desc';
interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  order?: Order;
  orderBy?: string;
  headCells: any[];
}

const EnhancedTableHead: React.FC<EnhancedTableProps> = (props) => {
  const { classes, order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.field}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={orderBy === headCell.field ? order : false}>
            {headCell.sort ? (
              <TableSortLabel
                style={{ width: headCell.width }}
                active={orderBy === headCell.field}
                direction={orderBy === headCell.field ? order : 'asc'}
                onClick={createSortHandler(headCell.field)}>
                {headCell.headerName}
                {orderBy === headCell.field ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              <>
                {headCell.ColumnTooltip ? (
                  <div className='flex'>
                    <p className='mr-2'>{headCell.headerName}</p>
                    <Tooltip
                      key={headCell.field}
                      title={
                        headCell.ColumnTooltip ? (
                          <p className='text-base'>{headCell.ColumnTooltip}</p>
                        ) : (
                          ''
                        )
                      }
                      placement='top-start'>
                      <InfoIcon color='disabled' fontSize='small' />
                    </Tooltip>
                  </div>
                ) : (
                  headCell.headerName
                )}
              </>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
