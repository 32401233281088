import { ApolloError, useMutation } from '@apollo/client';
import { POST_UPDATE_PRICE_PARENT } from './queries';

export interface IEndpointVariables {
  variables: {
    asin: string | undefined;
    fnsku: string | undefined;
    price: number;
  };
}

export interface IResponseExternal {
  ordersActiveListingPriceParent: IResponseInternal;
}

export interface IResponseInternal {
  status: boolean | undefined;
  data: string | undefined;
}

export interface IEndpointResponse {
  ordersActiveListingPriceParent: (variables: IEndpointVariables) => void;
  loading: boolean;
  data: IResponseExternal | undefined;
  error: ApolloError | undefined;
}

export const usePostUpdateProductPriceGraphql = (): IEndpointResponse => {
  const [ordersActiveListingPriceParent, { loading, data, error }] =
    useMutation(POST_UPDATE_PRICE_PARENT);

  return {
    ordersActiveListingPriceParent,
    loading,
    data,
    error,
  };
};
