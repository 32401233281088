import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import TodayIcon from '@material-ui/icons/Today';
import BusinessIcon from '@material-ui/icons/Business';
import EmailIcon from '@material-ui/icons/Email';
import PersonIcon from '@material-ui/icons/Person';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import useGetOrderDetail from 'api/services/removalReturns/getOrderDetail';
import StatusChip from 'components/UI/StatusChip';
import Divider from '@material-ui/core/Divider';
import useDateFormat from 'hooks/useDateFormat';
import useAuthContext from '@base/common/hooks/auth-context';
import { useParams } from 'react-router-dom';
import {
  textTooltipInternalStatus,
  textTooltipOrderStatus,
} from '../constants';

const ListDetail: React.FC = () => {
  const { userId } = useAuthContext();
  const { id: urlIdOrderDetail } = useParams<any>();

  const { response } = useGetOrderDetail({
    orderId: urlIdOrderDetail,
    userId,
  });

  return (
    <div className='md:grid grid-cols-2'>
      <List className='w-full'>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <BorderColorIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={response?.data.orderId} secondary='Order ID' />
        </ListItem>
        <Divider variant='inset' component='li' />
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <FormatListNumberedIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={response?.data.itemsQty}
            secondary='Items Qty'
          />
        </ListItem>
        <Divider variant='inset' component='li' />

        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <TodayIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={useDateFormat(response?.data.createdAt)}
            secondary='Create At'
          />
        </ListItem>
        <Divider variant='inset' component='li' />
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <BusinessIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={response?.data.mwsSellerId}
            secondary='Marketplace Seller ID'
          />
        </ListItem>
        <Divider variant='inset' component='li' />
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <EmailIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={response?.data.customerEmail}
            secondary='Customer Email'
          />
        </ListItem>
      </List>
      <List className='w-full'>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <PersonIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={response?.data.customerFirstName}
            secondary='Customer First Name'
          />
        </ListItem>
        <Divider variant='inset' component='li' />
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <PersonIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={response?.data.customerLastName}
            secondary='Customer Last Name'
          />
        </ListItem>
        <Divider variant='inset' component='li' />
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <BusinessIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={response?.data.company} secondary='Company' />
        </ListItem>
        <Divider variant='inset' component='li' />
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <LocalShippingIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <StatusChip
                type={response?.data.orderStatus || ''}
                textTooltip={textTooltipOrderStatus}
              />
            }
            secondary='Order Status'
          />
        </ListItem>
        <Divider variant='inset' component='li' />
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <EqualizerIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <StatusChip
                type={response?.data.internalStatus || ''}
                textTooltip={textTooltipInternalStatus}
              />
            }
            secondary='Internal Status'
          />
        </ListItem>
      </List>
    </div>
  );
};

export default ListDetail;
