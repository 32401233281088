import React from 'react';
import FilterPeriod from '../components/FilterPeriod';
import DetailsDiscounts from '../components/DetailsDiscounts';
import Result from '../components/Result';
import useGetFinances from 'api/services/finances/get';
import { useAuth } from '@base/common/hooks/auth-hook';
import { CircularProgress, Typography, Button } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import useFormatDate from '../hooks/useFormatDate';
import { useHistory } from 'react-router-dom';

const SalesDetails = () => {
  const { userId } = useAuth();

  const history = useHistory();

  const [dateMonthFilters, setDateMonthFilters] = React.useState(
    new Date().getMonth() + 1,
  );

  const [dateYearFilters, setDateYearFilters] = React.useState(
    new Date().getFullYear(),
  );

  const [FilterComplete, setFilterComplete] = React.useState({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  });

  const [data, setdata] = React.useState<any>([]);

  const { loading, response } = useGetFinances({
    userId,
    month: FilterComplete.month,
    year: FilterComplete.year,
  });

  React.useEffect(() => {
    setdata(response?.data[0]);
  }, [response]);

  const onClickFilter = () => {
    setFilterComplete({ month: dateMonthFilters, year: dateYearFilters });
  };

  const dateParams = useFormatDate({ dateMonthFilters, dateYearFilters });

  const redirectYourSales = () => {
    history.push('/your-sales', dateParams.dateParams);
  };

  const VerifyInterface = () => {
    if (!data) {
      return (
        <div className='flex justify-center flex-col items-center h-20 mt-12'>
          <ErrorOutlineIcon fontSize='large' />
          <Typography variant='h2' component='h2' gutterBottom>
            No data found
          </Typography>
        </div>
      );
    }

    return (
      <>
        <DetailsDiscounts data={data} />
        <Result data={data} />
      </>
    );
  };

  return (
    <div>
      <FilterPeriod
        stateMonth={dateMonthFilters}
        setStateMonth={setDateMonthFilters}
        stateYear={dateYearFilters}
        setStateYear={setDateYearFilters}
        onClickFilter={onClickFilter}
      />

      {loading ? (
        <div className='flex justify-center mt-4'>
          <CircularProgress />
        </div>
      ) : (
        <VerifyInterface />
      )}

      <div className='flex justify-end mt-4 lg:mx-12'>
        <Button variant='contained' color='primary' onClick={redirectYourSales}>
          Go to Orders
        </Button>
      </div>
    </div>
  );
};

export default SalesDetails;
