import { gql } from '@apollo/client';

export const GET_EMAIL_SETTINGS = gql`
  query getEmailSettings($userId: String!) {
    settingsGetEmailNotification(userId: $userId) {
      data {
        emailNotification {
          boxRecieved
          itemSold
          removalOrders
        }
      }
      status
    }
  }
`;

export const POST_EMAIL_NOTIFICATION = gql`
  mutation postEmailNotification(
    $settings: EmailNotificationInput!
    $userId: String!
  ) {
    settingsAddEmailNotification(settings: $settings, userId: $userId) {
      data
      status
    }
  }
`;
