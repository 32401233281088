import { ApolloError } from '@apollo/client';
import {
  IEndpointVariables,
  useGetConectAmazonAccountsGraphql,
} from 'api/endpoints/amazonAccounts/get';
import AmazonAccountsTransform from 'api/transforms/AmazonAccounts';
import { IServiceResponseAmazon } from 'api/transforms/AmazonAccounts';
import { getErrorTransform } from 'api/transforms/errors';

interface IServiceState {
  loading: boolean;
  error: ApolloError | undefined | string;
  response: IServiceResponseAmazon | undefined;
  refetch: () => void;
}

const getConectAccounts = (
  endpointParams: IEndpointVariables,
): IServiceState => {
  const { loading, error, data, refetch } =
    useGetConectAmazonAccountsGraphql(endpointParams);

  if (data) {
    return {
      loading,
      error,
      response: AmazonAccountsTransform(data),
      refetch,
    };
  } else {
    return {
      loading,
      error: getErrorTransform(error),
      response: data,
      refetch,
    };
  }
};

export default getConectAccounts;
