import { ApolloError } from '@apollo/client';
import {
  IAcceptanceVars,
  useGetAcceptanceGraphql,
} from 'api/endpoints/acceptance/get';
import {
  getAcceptanceTransform,
  IServiceResponse,
} from 'api/transforms/Acceptance';
import { getErrorTransform } from 'api/transforms/errors';

interface IServiceState {
  loading: boolean;
  error: ApolloError | string | undefined;
  response: IServiceResponse | undefined;
}

const useGetAcceptance = (endpointParams: IAcceptanceVars): IServiceState => {
  const { loading, error, data } = useGetAcceptanceGraphql(endpointParams);

  if (data) {
    return {
      loading,
      error,
      response: getAcceptanceTransform(data),
    };
  } else {
    return {
      loading,
      error: getErrorTransform(error),
      response: data,
    };
  }
};

export default useGetAcceptance;
