import React from 'react';
import { Chip } from './style';
import { status } from 'modules/removalReturns/constants';
import TimerIcon from '@material-ui/icons/Timer';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import DoneIcon from '@material-ui/icons/Done';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import BackspaceIcon from '@material-ui/icons/Backspace';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import AllInboxIcon from '@material-ui/icons/AllInbox';

import { withStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';

export interface IStatusChip {
  type: string;
  textTooltip: IPropsTooltips;
}

interface IPropsTooltips {
  pending?: string;
  shipped?: string;
  cancelled?: string;
  partialReceived?: string;
  partialShipped?: string;
  completed?: string;
  received?: string;
  canceled?: string;
  overReceived?: string;
}

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const StatusChip: React.FC<IStatusChip> = ({ type, textTooltip }) => {
  const CheckTooltipShow = ({ icon, textTooltipRender }: any) => {
    return (
      <>
        <Chip icon={icon} label={type || 'none'} />
        <HtmlTooltip title={<>{textTooltipRender}</>}>
          <InfoIcon color='action' fontSize='small' className='ml-2' />
        </HtmlTooltip>
      </>
    );
  };

  const statusType = typeof type === 'string' ? type.toLowerCase() : type;

  if (statusType === status.pending) {
    return (
      <CheckTooltipShow
        icon={<TimerIcon />}
        textTooltipRender={textTooltip.pending}
      />
    );
  } else if (statusType === status.shipped) {
    return (
      <CheckTooltipShow
        icon={<LocalShippingIcon />}
        textTooltipRender={textTooltip.shipped}
      />
    );
  } else if (
    statusType === status.cancelled ||
    statusType === status.canceled
  ) {
    return (
      <CheckTooltipShow
        icon={<BackspaceIcon />}
        textTooltipRender={textTooltip.cancelled}
      />
    );
  } else if (statusType === status['partial received']) {
    return (
      <CheckTooltipShow
        icon={<AssignmentLateIcon />}
        textTooltipRender={textTooltip.partialReceived}
      />
    );
  } else if (statusType === status.received) {
    return (
      <CheckTooltipShow
        icon={<AssignmentTurnedInIcon />}
        textTooltipRender={textTooltip.partialReceived}
      />
    );
  } else if (statusType === status.completed) {
    return (
      <CheckTooltipShow
        icon={<DoneIcon />}
        textTooltipRender={textTooltip.completed}
      />
    );
  } else if (statusType === status['over received']) {
    return (
      <CheckTooltipShow
        icon={<AllInboxIcon />}
        textTooltipRender={textTooltip.overReceived}
      />
    );
  }
  else {
    return <Chip icon={null} label={type || 'none'} />;
  }
};

export default StatusChip;
