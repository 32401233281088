import React from 'react';

const Result = ({ data }: any) => {
  return (
    <div className='border-b-2 border-gray-400 pb-12 lg:mx-12 mt-9'>
      <p>What is the result?</p>
      <div className='flex justify-between'>
        <div>
          <p className='font-bold'>Closing Balance</p>
        </div>
        <div className='w-56 lg:w-96'>
          <ul>
            <li className='flex justify-between'>
              <p>Sales Amount</p>
              <p>${data.salesTotal}</p>
            </li>
            <li className='flex justify-between'>
              <p>Total fees</p>
              <p className='text-red-600'>-${data.totalFee}</p>
            </li>
          </ul>
          <div className='flex justify-end mt-2'>
            <div className='lg:ml-20 flex border-t-2 border-gray-600 justify-between'>
              <p className='w-20 lg:w-24'>Subtotal</p>
              <p className='text-blue-600'>${data.totalNet}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Result;
