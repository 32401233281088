import { gql } from '@apollo/client';

export const GET_ACTIVE_LISTINGS = gql`
  query getActiveListings(
    $userId: String!
    $reqPage: Int!
    $reqLimit: Int!
    $filters: ActiveListingFilter!
  ) {
    ordersGetActiveListing(
      userId: $userId
      reqPage: $reqPage
      reqLimit: $reqLimit
      filters: $filters
    ) {
      data {
        children {
          activeQty
          activeStatus
          fnsku
          name
          _id
          asin
          newAsin
          condition
          productPrice
          productMinPrice
          productMaxPrice
          productCustomerMinPrice
          productCustomerMaxPrice
        }
        mainProduct {
          itemName
          fnsku
          asin
          newAsin
          _id
          itemPrice
        }
      }
      page
      status
      totalDocs
      totalPages
    }
  }
`;

export const POST_UPDATE_PRICE_PARENT = gql`
  mutation updatePriceParent($asin: String!, $fnsku: String!, $price: Float!) {
    ordersActiveListingPriceParent(asin: $asin, fnsku: $fnsku, price: $price) {
      data
      status
    }
  }
`;

export const POST_UPDATE_PRICE = gql`
  mutation updatePriceChildren(
    $productId: String!
    $price: Float!
    $productCustomerMaxPrice: Float!
    $productCustomerMinPrice: Float!
  ) {
    ordersActiveListingPriceChildren(
      price: $price
      productCustomerMinPrice: $productCustomerMinPrice
      productCustomerMaxPrice: $productCustomerMaxPrice
      productId: $productId
    ) {
      status
      data
    }
  }
`;

export const GET_ORDERS_OFFERS = gql`
  query MyQuery($asin: String!, $channel: String!, $itemCondition: String!) {
    ordersGetOffers(
      asin: $asin
      channel: $channel
      itemCondition: $itemCondition
    ) {
      data {
        avg
        maxPrice
        minPrice
      }
      status
    }
  }
`;
