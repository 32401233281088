import React from 'react';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import ButtonColapse from './ButtonColapse';
import StatusChip from 'components/UI/StatusChip';
import useDateFormat from 'hooks/useDateFormat';
import EnhancedTableHead from './EnhancedTableHead';
import usePriceFormat from 'hooks/usePriceFormat';
import BurstModeIcon from '@material-ui/icons/BurstMode';
import { createTheme } from '@material-ui/core/styles';
import {
  makeStyles,
  createStyles,
  Theme,
  withStyles,
  ThemeProvider,
} from '@material-ui/core/styles';
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  DialogContent,
  Dialog,
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import { Link } from 'react-router-dom';

import ModifyMwsStatus from 'modules/amazonAccounts/containers/ModifyMwsStatus';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import CollapseTracking from 'modules/removalReturns/components/CollapseTracking';

import { ControllerUpdatePrice } from '../../../../modules/activeListings/containers/ControllerUpdatePrice';

const useRowStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
    buttonStyle: {
      margin: theme.spacing(1),
      color: '#FFF',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }),
);

const themes = createTheme({
  palette: {
    primary: green,
  },
});

const ColorButton = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
}))(Button);

export interface IRowCollapseProps {
  row: any;
  setVariablesPost?: any;
  buttonState?: boolean;
  loadingMutation?: boolean;
  options?: any;
  optionsCollapse?: any;
  dataCollapse?: any;
  titleProperty?: string;
  page?: number;
  rowsPerPage?: number;
  setField?: any;
  verifyProperty?: any;
  ComponentsView?: any;
  editComponent?: any;
  statusBox?: any;
  refetch?: any;
}

const RowCollapse: React.FC<IRowCollapseProps> = ({
  row,
  options,
  optionsCollapse,
  dataCollapse,
  titleProperty,
  setVariablesPost,
  buttonState,
  loadingMutation,
  page,
  rowsPerPage,
  editComponent,
  statusBox,
}) => {
  const [open, setOpen] = React.useState(false);
  const colSpan = Number(options.length) + 1 || 8;
  const [stateButton, setStateButton] = React.useState(true);
  const [openModalImage, setOpenModalImage] = React.useState(false);
  const classes = useRowStyles();
  const [arrayPhotos, setArrayphotos] = React.useState([]);

  const handleOpen = (item: any) => {
    setOpenModalImage(true);
    setArrayphotos(item.productPhotos);
  };

  const handleClose = () => {
    setOpenModalImage(false);
    setArrayphotos([]);
  };

  const clickButton = (orderId: string, process: string) => {
    setStateButton(false);
    setVariablesPost({ orderId, process });
    setTimeout(() => {
      setStateButton(true);
    }, 2000);
  };

  const VerifyTableCell = ({ rowVerifyTable, option }: any) => {
    const rowHasChildren = option.isActivePrice && dataCollapse?.length !== 0;

    const verifyTypeRow = () => {
      if (option.withChip) {
        return (
          <StatusChip
            type={rowVerifyTable[option.field]}
            textTooltip={option.textTooltip}
          />
        );
      }
      if (option.isPhoto) {
        return editComponent(rowVerifyTable[option.field]);
      }
      if (option.isStatusBox) {
        return statusBox(rowVerifyTable[option.field]);
      }

      if (rowHasChildren) {
        return '--';
      }
      if (option.isDate) {
        return useDateFormat(rowVerifyTable[option.field]);
      }
      if (option.isUpdatePrice) {
        return editComponent(
          rowVerifyTable.asin,
          rowVerifyTable.fnsku,
          'parent',
          rowVerifyTable.itemPrice,
          page,
          rowsPerPage,
          dataCollapse,
        );
      }
      if (option.isPrice) {
        return usePriceFormat(rowVerifyTable[option.field]);
      }
      if (option.isName) {
        return editComponent(rowVerifyTable[option.field], rowVerifyTable._id);
      }
      if (option.isEditState) {
        return (
          <ModifyMwsStatus
            accountId={rowVerifyTable._id}
            accountStatus={rowVerifyTable.mwsStatus}
          />
        );
      }
      if (
        option.field === 'detail' &&
        rowVerifyTable.toProcess === 'Accepted'
      ) {
        return (
          <Link
            to={{
              pathname: `/removal-orders/${rowVerifyTable.id}`,
              state: { page, rowsPerPage },
            }}>
            <Button
              data-testid='detailButton'
              variant='contained'
              color='primary'>
              Detail
            </Button>
          </Link>
        );
      }
      if (option.field === 'asin' || option.field === 'ASIN') {
        return (
          <Tooltip
            title={<p className='text-base'>See product on Amazon</p>}
            placement='bottom-start'>
            <a
              className='hover:text-blue-500 flex items-end'
              rel='noreferrer'
              target='_blank'
              href={`https://www.amazon.com/dp/${
                rowVerifyTable[option.field]
              }`}>
              <p>{rowVerifyTable[option.field]}</p>
              <img
                src='/images/iconAmazon.png'
                alt='icon Amazon'
                className='w-6 mx-3 cursor-pointer'
              />
            </a>
          </Tooltip>
        );
      }

      return rowVerifyTable[option.field];
    };

    return (
      <TableCell
        width={option.width || 'auto'}
        align='left'
        component='th'
        scope='row'>
        {verifyTypeRow()}
      </TableCell>
    );
  };

  const VerifyTableCellCollapse = ({ rowVerifyTable, option }: any) => {
    const verifyTypeRow = () => {
      if (option.isDate) {
        return useDateFormat(rowVerifyTable[option.field]);
      }
      if (option.isUpdatePrice) {
        return (
          <ControllerUpdatePrice
            productId={rowVerifyTable._id}
            asin={rowVerifyTable.asin}
            condition={rowVerifyTable.condition}
          />
        );
      }
      if (option.isPriceMin) {
        return usePriceFormat(
          rowVerifyTable.productCustomerMinPrice
            ? rowVerifyTable.productCustomerMinPrice
            : rowVerifyTable.productMinPrice,
        );
      }
      if (option.isPriceMax) {
        return usePriceFormat(
          rowVerifyTable.productCustomerMaxPrice
            ? rowVerifyTable.productCustomerMaxPrice
            : rowVerifyTable.productMaxPrice,
        );
      }
      if (option.field === 'ItemPrice') {
        return usePriceFormat(rowVerifyTable.ItemPrice?.Amount);
      }
      if (option.isPrice) {
        return usePriceFormat(rowVerifyTable[option.field]);
      }
      if (option.isTrackingCollapse) {
        return (
          <>
            <CollapseTracking rowVerifyTable={rowVerifyTable} option={option} />
          </>
        );
      }
      if (option.field === 'asin' || option.field === 'ASIN') {
        return (
          <Tooltip
            title={<p className='text-base'>See product on Amazon</p>}
            placement='bottom-start'>
            <a
              className='hover:text-blue-500 flex items-end'
              rel='noreferrer'
              target='_blank'
              href={`https://www.amazon.com/dp/${
                rowVerifyTable[option.field]
              }`}>
              <p>{rowVerifyTable[option.field]}</p>
              <img
                src='/images/iconAmazon.png'
                alt='icon Amazon'
                className='w-6 mx-3 cursor-pointer'
              />
            </a>
          </Tooltip>
        );
      }
      return rowVerifyTable[option.field];
    };
    return (
      <TableCell
        width={option.width || 'auto'}
        align='left'
        component='th'
        scope='row'>
        {verifyTypeRow()}
      </TableCell>
    );
  };

  return (
    <React.Fragment>
      <>
        <TableRow className={classes.root} key={row.id}>
          {dataCollapse && (
            <TableCell>
              {dataCollapse?.length === 0 ? (
                <Tooltip title='There are no associated items'>
                  <span>
                    <ButtonColapse
                      open={open}
                      setOpen={setOpen}
                      disabled
                      testId={'buttonCollapseDisabled'}
                    />
                  </span>
                </Tooltip>
              ) : (
                <ButtonColapse
                  open={open}
                  setOpen={setOpen}
                  disabled={false}
                  testId={'buttonCollapse'}
                />
              )}
            </TableCell>
          )}
          {options?.map((option: any, index: any) => (
            <React.Fragment key={index}>
              {!option.isButton && (
                <>
                  {option.field !== '' ? (
                    <VerifyTableCell
                      option={option}
                      rowVerifyTable={row}
                      index={index}
                    />
                  ) : (
                    <TableCell component='th' scope='row' className='flex'>
                      <Link
                        to={{
                          pathname: `/removal-orders/${row.id}`,
                          state: { page, rowsPerPage },
                        }}>
                        <Button
                          data-testid='detailButton'
                          variant='contained'
                          color='primary'>
                          Detail
                        </Button>
                      </Link>
                    </TableCell>
                  )}
                </>
              )}
            </React.Fragment>
          ))}
          {buttonState && (
            <TableCell align='left'>
              <div className={classes.buttonStyle}>
                {loadingMutation && !stateButton ? (
                  <CircularProgress />
                ) : (row.toProcess === undefined && stateButton) ||
                  (row.toProcess === null &&
                    stateButton &&
                    row.internalStatus === 'Pending' &&
                    row.orderStatus === 'Pending') ||
                  (row.toProcess === null &&
                    stateButton &&
                    row.internalStatus === 'pending' &&
                    row.orderStatus === 'pending') ? (
                  <>
                    <ThemeProvider theme={themes}>
                      <Button
                        size='small'
                        variant='contained'
                        color='primary'
                        className={classes.buttonStyle}
                        onClick={() => clickButton(row.id, 'Accepted')}>
                        Send to our warehouse
                      </Button>
                    </ThemeProvider>
                    <ColorButton
                      size='small'
                      variant='contained'
                      color='primary'
                      className={classes.buttonStyle}
                      onClick={() => clickButton(row.id, 'Discarded')}>
                      Discard order
                    </ColorButton>
                  </>
                ) : (row.toProcess === undefined && stateButton) ||
                  (row.toProcess === null &&
                    stateButton &&
                    row.internalStatus !== 'pending' &&
                    row.internalStatus !== 'Pending') ? (
                  <>
                    <ThemeProvider theme={themes}>
                      <Tooltip title='This order has items already received in our warehouse. You cannot discard the order.'>
                        <span>
                          <Button
                            size='small'
                            variant='contained'
                            disabled
                            color='primary'
                            className={classes.buttonStyle}
                            onClick={() => clickButton(row.id, 'Accepted')}>
                            Send to our warehouse
                          </Button>
                        </span>
                      </Tooltip>
                    </ThemeProvider>
                    <Tooltip title='This order has items already received in our warehouse. You cannot discard the order.'>
                      <span>
                        <ColorButton
                          size='small'
                          variant='contained'
                          disabled
                          color='primary'
                          className={classes.buttonStyle}
                          onClick={() => clickButton(row.id, 'Discarded')}>
                          Discard order
                        </ColorButton>
                      </span>
                    </Tooltip>
                  </>
                ) : row.toProcess === 'Accepted' ? (
                  <CheckCircleOutlineIcon style={{ color: green[500] }} />
                ) : (
                  row.toProcess === 'Discarded' && (
                    <RemoveCircleOutlineIcon style={{ color: red[500] }} />
                  )
                )}
              </div>
            </TableCell>
          )}
        </TableRow>
        {dataCollapse && (
          <TableRow>
            <TableCell
              style={{ paddingBottom: 0, paddingTop: 0 }}
              colSpan={colSpan}>
              <Collapse in={open} timeout='auto' unmountOnExit>
                <Box margin={1}>
                  <Typography variant='h6' gutterBottom component='div'>
                    {titleProperty}
                  </Typography>
                  <Table size='medium' aria-label='purchases'>
                    <EnhancedTableHead
                      headCells={optionsCollapse}
                      classes={classes}
                      onRequestSort={optionsCollapse}
                    />
                    <TableBody>
                      {dataCollapse?.map((item: any, index: any) => (
                        <TableRow key={index}>
                          <>
                            {optionsCollapse?.map(
                              (option: any, optionIndex: any) => (
                                <React.Fragment key={optionIndex}>
                                  {option.field !== 'productPhotos' ? (
                                    <VerifyTableCellCollapse
                                      option={option}
                                      rowVerifyTable={item}
                                    />
                                  ) : (
                                    <>
                                      <TableCell component='th' scope='row'>
                                        <Button
                                          style={{
                                            backgroundColor: 'green',
                                          }}
                                          variant='contained'
                                          color='primary'
                                          onClick={() => handleOpen(item)}>
                                          <BurstModeIcon
                                            className='mr-2'
                                            style={{
                                              fontSize: '2.3rem',
                                            }}
                                          />
                                          See images
                                        </Button>
                                        <Dialog
                                          fullWidth
                                          maxWidth='md'
                                          open={openModalImage}
                                          onClose={handleClose}
                                          data-testid='modalPhotos'
                                          aria-labelledby='simple-modal-title'
                                          aria-describedby='simple-modal-description'>
                                          <DialogContent>
                                            <Carousel>
                                              {arrayPhotos.map(
                                                (
                                                  photo: string,
                                                  indexPhoto: any,
                                                ) => (
                                                  <div key={indexPhoto}>
                                                    <img src={photo} />
                                                  </div>
                                                ),
                                              )}
                                            </Carousel>
                                          </DialogContent>
                                        </Dialog>
                                      </TableCell>
                                    </>
                                  )}
                                </React.Fragment>
                              ),
                            )}
                          </>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        )}
      </>
    </React.Fragment>
  );
};

export default RowCollapse;
