import React from 'react';
import EditName from 'modules/amazonAccounts/containers/EditName';
import usePriceFormat from 'hooks/usePriceFormat';
import useDateFormat from 'hooks/useDateFormat';
import ModifyMwsStatus from 'modules/amazonAccounts/containers/ModifyMwsStatus';
import Tooltip from '@material-ui/core/Tooltip';
import { TableCell, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

export interface IRowProps {
  rows: any[];
  options: any[];
}

const Row: React.FC<IRowProps> = ({ rows, options }) => {
  const classes = useRowStyles();

  return (
    <React.Fragment>
      {rows?.map((row: any, index) => (
        <TableRow key={index} className={classes.root}>
          {options.map((option: any) => (
            <TableCell
              key={option.field}
              align={option.bodyAlign || 'left'}
              component='th'
              scope='row'>
              {option.isPrice ? (
                usePriceFormat(row[option.field])
              ) : option.isDate ? (
                useDateFormat(row[option.field])
              ) : option.isButton ? (
                <ModifyMwsStatus
                  accountId={row._id}
                  accountStatus={row.mwsStatus}
                />
              ) : option.isName ? (
                <EditName field={row[option.field]} id={row._id} />
              ) : option.field === 'asin' || option.field === 'ASIN' ? (
                <Tooltip
                  title={<p className='text-base'>See product on Amazon</p>}
                  placement='bottom-start'>
                  <a
                    className='hover:text-blue-500 flex items-end'
                    rel='noreferrer'
                    target='_blank'
                    href={`https://www.amazon.com/dp/${row[option.field]}`}>
                    {row[option.field]}
                    <img
                      src='/images/iconAmazon.png'
                      alt='icon Amazon'
                      className='w-6 mx-3 cursor-pointer'
                    />
                  </a>
                </Tooltip>
              ) : (
                row[option.field]
              )}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </React.Fragment>
  );
};

export default Row;
