import { useMutation } from '@apollo/client';
import { POST_UPDATE_MWS_ACCOUNT } from './queries';

export interface IUpdateData {
  mwsName?: string | undefined;
  mwsStatus?: string | undefined;
}

export interface IEndpointVariables {
  variables: {
    accountId: string;
    userId: string | null;
    updateData: IUpdateData;
  };
}

export interface IResponseExternal {
  updateMwsAccount: IResponseInternal;
}

export interface IDataResponse {
  mwsName: string;
  mwsStatus: boolean | string;
}

export interface IResponseInternal {
  status: boolean | undefined;
  data: IDataResponse | undefined;
}

export interface IEndpointResponse {
  updateMwsAccount: (variables: IEndpointVariables) => void;
  loading: boolean;
  data: IResponseExternal | undefined;
}

export const usePostUpdateMwsAccountGraphql = (): IEndpointResponse => {
  const [updateMwsAccount, { loading, data }] = useMutation(
    POST_UPDATE_MWS_ACCOUNT,
  );

  return {
    updateMwsAccount,
    loading,
    data,
  };
};
