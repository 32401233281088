export const dataTableActiveListingsFields = [
  {
    field: 'itemName',
    headerName: "Item's Name",
    sort: false,
  },
  {
    field: 'asin',
    headerName: 'ASIN',
    sort: false,
  },
  // TODO: Add this field to the table
  // {
  //   field: 'activeQty',
  //   headerName: 'Active Qty',
  //   sort: false,
  // },
];

export const dataTableHeaders = [
  { field: 'items', headerName: 'Items' },
  ...dataTableActiveListingsFields,
];

export const dataFieldsCollapse = [
  {
    field: 'condition',
    headerName: 'Condition',
    sort: false,
  },
  {
    field: 'fnsku',
    headerName: 'FNSKU',
    sort: false,
  },
  {
    field: 'asin',
    headerName: 'ASIN',
    sort: false,
  },
  {
    field: 'newAsin',
    headerName: 'Optional ASIN',
    sort: false,
    ColumnTooltip:
      'We will show here the current ASIN in case Amazon has changed original ASIN.',
  },
  {
    field: 'activeQty',
    headerName: 'Active Qty',
    sort: false,
  },
  {
    field: 'activeStatus',
    headerName: 'Active Status',
    sort: false,
  },
  {
    field: 'productCustomerMinPrice',
    headerName: 'Min Price',
    isPriceMin: true,
  },
  {
    field: 'productCustomerMaxPrice',
    headerName: 'Max Price',
    isPriceMax: true,
  },

  {
    field: 'action',
    headerName: 'Action',
    isUpdatePrice: true,
  },
];

export const inputsFilters = [
  { id: 'asin', name: 'asin', label: 'ASIN' },
  { id: 'fnsku', name: 'fnsku', label: 'FNSKU' },
];

export const selectFilters = [
  {
    name: 'condition',
    menuItem: [
      {
        value: '',
        labelName: 'Condition',
        disabled: true,
      },
      {
        value: 'NewItem',
        labelName: 'New Item',
        disabled: false,
      },
      {
        value: 'NewOpenBox',
        labelName: 'New Open Box',
      },
      {
        value: 'UsedLikeNew',
        labelName: 'Used Like New',
      },
      {
        value: 'UsedVeryGood',
        labelName: 'Used Very Good',
        disabled: false,
      },
      {
        value: 'UsedGood',
        labelName: 'Used Good',
      },
      {
        value: 'UsedAcceptable',
        labelName: 'Used Acceptable',
      },
      {
        value: 'UsedPoor',
        labelName: 'Used Poor',
      },
      {
        value: 'Refurbished',
        labelName: 'Refurbished',
      },
      {
        value: 'MissingParts',
        labelName: 'Missing Parts',
      },
    ],
  },
];

export const dataProperty = 'children';
export const dataCollapseActiveListings = 'children';
export const titleCollapseActiveListings = 'Items';

export const dataTableRowsPerPage = 10;
