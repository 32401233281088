import { ApolloError } from '@apollo/client';
import {
  IActiveListingsVars,
  useGetActiveListingsGraphql,
} from 'api/endpoints/activeListings/get';
import {
  getActiveListingTransform,
  IServiceResponse,
} from 'api/transforms/ActiveListings';
import { getErrorTransform } from 'api/transforms/errors';

interface IServiceState {
  loading: boolean;
  error: ApolloError | string | undefined;
  response: IServiceResponse | undefined;
  refetch: () => void;
}

const useGetActiveListings = (
  endpointParams: IActiveListingsVars,
): IServiceState => {
  const { loading, error, data, refetch } =
    useGetActiveListingsGraphql(endpointParams);

  if (data) {
    return {
      loading,
      error,
      response: getActiveListingTransform(data),
      refetch,
    };
  } else {
    return {
      loading,
      error: getErrorTransform(error),
      response: data,
      refetch,
    };
  }
};

export default useGetActiveListings;
