export const amazonAccountUrl = (userId: string | null): string => {
  return `https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.216a33be-662f-4042-bc1a-3e6db1571791&state=${userId}&version=beta`;
};
export const amazonRedirectSPAPI = 'spapi_oauth_code';
export const amazonRedirectState = 'state';
export const amazonRedirectSellingPartnerId = 'selling_partner_id';
export const dataTableFields = [
  {
    field: 'mwsSellerId',
    headerName: 'Marketplace Seller ID',
    sort: true,
  },
  {
    field: 'mwsName',
    headerName: 'Marketplace Name',
    sort: true,
    isName: true,
  },
  {
    field: 'mwsStatus',
    headerName: 'Marketplace Status',
    sort: true,
  },
  {
    field: 'button',
    headerName: '',
    sort: false,
    isEditState: true,
  },
];

export const dataTableRowsPerPage = 25;
