import React from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const NoMatch = () => {
  const history = useHistory();

  const redirect = () => {
    history.push('/dashboard');
  };

  return (
    <div className='flex justify-center pt-20 items-center flex-col'>
      <div>
        <p className='text-9xl font-bold'>404</p>
        <p className='text-center text-3xl mb-8'>Page no found</p>
      </div>

      <Button variant='contained' color='primary' onClick={redirect}>
        Back to Dashboard
      </Button>
    </div>
  );
};

export default NoMatch;
