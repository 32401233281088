import { ApolloError, useMutation } from '@apollo/client';
import { POST_UPDATE_PRICE } from './queries';
import { MutationHookOptions } from '@apollo/client/react/types/types';

export interface IEndpointVariables {
  variables: {
    productId: string | undefined;
    price: number | undefined;
    productCustomerMinPrice: number | undefined;
    productCustomerMaxPrice: number | undefined;
  };
}

export interface IResponseExternal {
  ordersActiveListingPriceChildren: IResponseInternal;
}

export interface IResponseInternal {
  status: boolean | undefined;
  data: string | undefined;
}

export interface IEndpointResponse {
  ordersActiveListingPriceChildren: (variables: IEndpointVariables) => void;
  loading: boolean;
  data: IResponseExternal | undefined;
  error: ApolloError | undefined;
}

export const usePostUpdateProductPriceChildrenGraphql = (
  options: MutationHookOptions,
): IEndpointResponse => {
  const [ordersActiveListingPriceChildren, { loading, data, error }] =
    useMutation(POST_UPDATE_PRICE, options);

  return {
    ordersActiveListingPriceChildren,
    loading,
    data,
    error,
  };
};
