import { ApolloError } from '@apollo/client';
import {
  IFinancesPaymentsVars,
  useGetFinancesPaymentsGraphql,
} from 'api/endpoints/finances/getPayments';
import FinancesPaymentsTranform, {
  IServiceResponseFinancesPayments,
} from 'api/transforms/finanacesPayments';
import { getErrorTransform } from '../../transforms/errors';

interface IServiceState {
  loading: boolean;
  error: ApolloError | undefined | string;
  response: IServiceResponseFinancesPayments | undefined;
  refetch: () => void;
}

const useGetFinancesPayments = (
  endpointParams: IFinancesPaymentsVars,
): IServiceState => {
  const { loading, error, data, refetch } =
    useGetFinancesPaymentsGraphql(endpointParams);

  if (data) {
    return {
      loading,
      error,
      response: FinancesPaymentsTranform(data),
      refetch,
    };
  } else {
    return {
      loading,
      error: getErrorTransform(error),
      response: data,
      refetch,
    };
  }
};

export default useGetFinancesPayments;
