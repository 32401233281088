import {
  IResponseExternal,
  IResponseInternal,
} from 'api/endpoints/emailSettings/post';
import {
  IEmailSettingsExternal,
  IEmailSettingsInternal,
} from 'interfaces/EmailSettings';

export interface IServiceResponse {
  data: IEmailSettingsInternal;
  status: boolean;
}

export const getEmailSettingsTransform = (
  data: IEmailSettingsExternal,
): IServiceResponse => {
  const newData = data.settingsGetEmailNotification.data;
  const status = data.settingsGetEmailNotification.status;

  return { data: newData, status };
};

export const postEmailNotificationTransform = (
  data: IResponseExternal | undefined,
): IResponseInternal => {
  const newData = data?.settingsAddEmailNotification.data;
  const status = data?.settingsAddEmailNotification.status;

  return {
    data: newData,
    status,
  };
};
