import React from 'react';
import useGetActiveListings from 'api/services/activeListings/get';
import useAuthContext from '@base/common/hooks/auth-context';
import Alert from '@material-ui/lab/Alert';
import FilterSection from 'components/UI/filters/FilterSection';
import DataTableTest from 'components/UI/DataTable/containers/dataTableTest';
import UpdatePrice from './UpdatePrice';
import useOptionsPage from 'hooks/useOptionsPage';
import {
  dataCollapseActiveListings,
  dataFieldsCollapse,
  dataTableActiveListingsFields,
  dataTableHeaders,
  dataTableRowsPerPage,
  titleCollapseActiveListings,
  inputsFilters,
  selectFilters,
} from '../constants';
import useStatesActiveLinstings from 'hooks/useStateActiveListing';

const ActiveListings = () => {
  const { userId } = useAuthContext();
  const { page, setPage, rowsPerPage, setRowsPerPage, validatePage } =
    useOptionsPage();
  const [filterOptions, setFilterOptions] = React.useState({
    asin: '',
    fnsku: '',
    condition: '',
  });
  const [filterComplete, setFilterComplete] = React.useState(filterOptions);
  const { loading, error, response, refetch } = useGetActiveListings({
    userId,
    reqPage: page + 1,
    reqLimit: rowsPerPage,
    filters: filterComplete,
  });

  const { refetchActiveListings, setRefetchActiveListings } =
    useStatesActiveLinstings();

  React.useEffect(() => {
    if (refetchActiveListings) {
      refetch();
      setRefetchActiveListings(false);
    }
  }, [refetchActiveListings]);

  React.useEffect(() => {
    setRowsPerPage(dataTableRowsPerPage);
  }, []);

  if (error)
    return (
      <Alert data-testid='alertErrorService' severity='error'>
        {error}
      </Alert>
    );

  return (
    <div className='mt-10'>
      <FilterSection
        inputsFilters={inputsFilters}
        setFilterOptions={setFilterOptions}
        filterOptions={filterOptions}
        selectFilters={selectFilters}
        setFilterComplete={setFilterComplete}
        validateField={['asin', 'fnsku']}
        setPage={setPage}
      />
      <DataTableTest data={response?.data as any[]} loading={loading}>
        <DataTableTest.Container>
          <DataTableTest.Headers
            headers={dataTableHeaders}
            sortBy={'orderId'}
          />
          <DataTableTest.body>
            <DataTableTest.Row
              refetch={refetch}
              dataFields={dataTableActiveListingsFields}
              dataFieldsCollapse={dataFieldsCollapse}
              titleCollapse={titleCollapseActiveListings}
              dataCollapse={dataCollapseActiveListings}
              editComponent={(
                asin: any,
                fnsku: any,
                type: any,
                price: any,
                pageProps: any,
                rowsPerpage: any,
                dataCollapse: any,
              ) => (
                <UpdatePrice
                  asin={asin}
                  fnsku={fnsku}
                  type={type}
                  price={price}
                  page={pageProps}
                  rowsPerPage={rowsPerpage}
                  dataCollapse={dataCollapse}
                  refetch={refetch}
                />
              )}
            />
          </DataTableTest.body>
        </DataTableTest.Container>
        <DataTableTest.Pagination
          count={response?.meta.totalDocs || 0}
          page={validatePage(loading, page)}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
        <DataTableTest.DensenPadding />
      </DataTableTest>
    </div>
  );
};

export default ActiveListings;
