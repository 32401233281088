import { ISalesExternal } from 'interfaces/Sales';
import { IsalesInternal } from '../../interfaces/Sales';

export interface IServiceResponse {
  meta: {
    totalDocs: number;
    totalPages: number;
  };
  data: IsalesInternal[];
}

export const getSalesTransform = (data: ISalesExternal): IServiceResponse => {
  const newData = data.purchaseOrdersCustomers.data;

  const meta = {
    totalDocs: data.purchaseOrdersCustomers.totalDocs,
    totalPages: data.purchaseOrdersCustomers.totalPages,
  };

  return { meta, data: newData };
};
