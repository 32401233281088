import { ApolloError } from '@apollo/client';
import {
  IEndpointVariables,
  IResponseInternal,
  usePostUpdateProductPriceChildrenGraphql,
} from 'api/endpoints/activeListings/postUpdateChildren';
import { usePostUpdateProductPriceChildrenTransform } from 'api/transforms/ActiveListings';
import { getErrorTransform } from 'api/transforms/errors';
import { MutationHookOptions } from '@apollo/client/react/types/types';

export interface IServiceResponse {
  ordersActiveListingPriceChildren: (variables: IEndpointVariables) => void;
  loading: boolean;
  data: IResponseInternal | undefined;
  error: ApolloError | string | undefined;
}

const usePostUpdateProductPriceChildren = (
  options: MutationHookOptions,
): IServiceResponse => {
  const { ordersActiveListingPriceChildren, loading, data, error } =
    usePostUpdateProductPriceChildrenGraphql(options);

  return {
    ordersActiveListingPriceChildren,
    loading,
    data: usePostUpdateProductPriceChildrenTransform(data),
    error: getErrorTransform(error),
  };
};

export default usePostUpdateProductPriceChildren;
