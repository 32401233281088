import React from 'react';

const useSafeFunction = (functionCall: any) => {
  const mountedRef = React.useRef(false);

  React.useLayoutEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return React.useCallback(
    (...args) => {
      if (mountedRef.current) {
        functionCall(...args);
      }
    },
    [functionCall],
  );
};

export default useSafeFunction;
