import React from 'react';
import { Typography } from '@material-ui/core';
import FinancesContainer from './containers/FinancesContainer';

const FinancesModule = () => {
  return (
    <div data-testid='financesPage' className='bg-white p-7'>
      <Typography variant='h1' component='h2' gutterBottom>
        Finances
      </Typography>
      <FinancesContainer />
    </div>
  );
};

export default FinancesModule;
