import { ISkuInternal, ISkuExternal } from 'interfaces/Sku';

export interface IserviceResponseSku {
  meta: {
    totalDocs: number;
    totalPages: number;
  };
  data: ISkuInternal[];
}

const SkuTransform = (data: ISkuExternal): IserviceResponseSku => {
  const transformData = data.skusByUser.data;

  const meta = {
    totalDocs: data.skusByUser.totalDocs,
    totalPages: data.skusByUser.totalPages,
  };

  return { meta, data: transformData };
};

export default SkuTransform;
