import React from 'react';
import PaymentDetails from './containers';
import { Typography } from '@material-ui/core';

const PaymentDetailsModule: React.FC = () => {
  return (
    <div data-testid='paymentDetailsPage' className='bg-white p-5'>
      <Typography variant='h1' component='h2' gutterBottom>
        Payment Details
      </Typography>
      <PaymentDetails />
    </div>
  );
};

export default PaymentDetailsModule;
