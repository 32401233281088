import Alert from '@material-ui/lab/Alert';
import React from 'react';
import FilterSection from 'components/UI/filters/FilterSection';
import useGetAcceptance from 'api/services/acceptance/get';
import useAuthContext from '@base/common/hooks/auth-context';
import DataTableTest from 'components/UI/DataTable/containers/dataTableTest';
import useOptionsPage from 'hooks/useOptionsPage';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import {
  itemFilters,
  dataTableHeaders,
  dataTableRowsPerPage,
  dateFilters,
} from '../constants';

export const useStylesContainer = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(2),
        width: '25ch',
      },
    },
    formControl: {
      margin: theme.spacing(2),
      minWidth: 120,
      flexDirection: 'row',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
      flex: 1,
    },
    rootSwitch: {
      margin: theme.spacing(2),
    },
    container: {
      display: 'flex',
    },
  }),
);

interface IFilterOptions {
  sku?: string;
  fnsku?: string;
  asin?: string;
  dateStart?: string | undefined | null;
  dateEnd?: string | undefined | null;
}

const AcceptanceList: React.FC = () => {
  const { userId } = useAuthContext();
  const { page, setPage, rowsPerPage, setRowsPerPage, validatePage } =
    useOptionsPage();
  const [filterOptions, setFilterOptions] = React.useState<IFilterOptions>({
    sku: '',
    fnsku: '',
    asin: '',
    dateStart: undefined,
    dateEnd: undefined,
  });

  const [filterComplete, setFilterComplete] = React.useState(filterOptions);
  const { loading, error, response } = useGetAcceptance({
    userId,
    reqPage: page + 1,
    reqLimit: rowsPerPage,
    filters: filterComplete,
  });

  React.useEffect(() => {
    setRowsPerPage(dataTableRowsPerPage);
  }, []);

  if (error)
    return (
      <Alert data-testid='alertErrorService' severity='error'>
        {error}
      </Alert>
    );

  return (
    <>
      <FilterSection
        inputsFilters={itemFilters}
        dateFilters={dateFilters}
        setFilterOptions={setFilterOptions}
        filterOptions={filterOptions}
        isValidateDate
        setFilterComplete={setFilterComplete}
        setPage={setPage}
      />
      <div className='mt-10'>
        <DataTableTest data={response?.data as any[]} loading={loading}>
          <DataTableTest.Container>
            <DataTableTest.Headers
              headers={dataTableHeaders}
              sortBy={'orderId'}
            />
            <DataTableTest.body>
              <DataTableTest.Row dataFields={dataTableHeaders} />
            </DataTableTest.body>
          </DataTableTest.Container>
          <DataTableTest.Pagination
            count={response?.meta.totalDocs || 0}
            page={validatePage(loading, page)}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
          <DataTableTest.DensenPadding />
        </DataTableTest>
      </div>
    </>
  );
};

export default AcceptanceList;
