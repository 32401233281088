import React, { useContext } from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { useHttpClient } from '@base/common/hooks/http-hook';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button, TextField, Link, Typography } from '@material-ui/core';
import ErrorModal from '@base/common/UI/ErrorModal';
import LoadingSpinner from '@base/common/UI/LoadingSpinner';
import { AuthContext } from '@base/common/context/auth-context';
import { useConfirmEmail } from '@base/common/hooks/confirmemail-hook';
import { useForm } from '@base/common/hooks/form-hook';
import { useHistory } from 'react-router-dom';

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64,
    },
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
  },
  grid: {
    height: '100%',
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  quote: {
    backgroundColor: '#f4f6f8',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage:
      'url(https://cdn.pixabay.com/photo/2016/06/27/11/20/warehouse-1482301_1280.jpg)',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px',
  },
  quoteText: {
    color: theme.palette.primary,
    fontWeight: 300,
    marginTop: theme.spacing(90),
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white,
  },
  bio: {
    color: theme.palette.white,
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  logoImage: {
    marginLeft: theme.spacing(4),
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  form: {
    paddingLeft: 100,
    paddingRight: 75,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(3),
  },
  socialButtons: {
    marginTop: theme.spacing(3),
  },
  socialIcon: {
    marginRight: theme.spacing(1),
  },
  sugestion: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
}));

const SignIn = (props) => {
  const navigation = useHistory();
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const classes = useStyles();
  const { hasError, handleChange, formState } = useForm(schema);

  const { confirmEmail, clearConfirmEmail, emailConfirmed } = useConfirmEmail();

  const handleSignIn = async (event) => {
    event.preventDefault();
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/api/users/login',
        'POST',
        JSON.stringify({
          email: formState.values.email,
          password: formState.values.password,
          project: 'customers',
        }),
        {
          'Content-Type': 'application/json',
        },
      );

      if (responseData.token) {
        auth.login(
          responseData.userId,
          responseData.email,
          responseData.tenant,
          responseData.token,
          null,
          true,
          responseData.status,
          responseData.emailVerified,
          responseData.linkedAccounts,
        );
        navigation.push('/dashboard');
      }
    } catch (err) {}
  };

  return (
    <div data-testid='signUpPage' className={classes.root}>
      {confirmEmail && (
        <>
          {emailConfirmed === true && (
            <ErrorModal
              open={!!confirmEmail}
              alert={
                <div className='flex flex-col items-center'>
                  <CheckCircleIcon
                    fontSize='large'
                    className='text-green-500'
                  />
                  <h3 className='text-xl font-semibold mb-5'>
                    Congratulations
                  </h3>
                  <p className='text-base'>
                    Your account has been activated successfully
                  </p>
                  <p className='text-base'>
                    You can now sign in to your account
                  </p>
                </div>
              }
              buttonClose={true}
              clearError={clearConfirmEmail}
            />
          )}

          {emailConfirmed === false && (
            <ErrorModal
              open={!!confirmEmail}
              title='Email Confirmation'
              alert={confirmEmail}
              clearError={clearConfirmEmail}
              buttonClose={true}
            />
          )}
        </>
      )}
      {error && (
        <ErrorModal
          open={!!error}
          title='Error Signing Up'
          alert={error}
          clearError={clearError}
          buttonClose={true}
        />
      )}
      {auth.error && (
        <ErrorModal
          open={!!auth.error}
          title='Error Signing Up'
          alert={auth.error}
          clearError={auth.clearError}
          buttonClose={true}
        />
      )}
      {isLoading && <LoadingSpinner open={isLoading} />}
      <Grid className={classes.grid} container>
        <Grid className={classes.quoteContainer} item lg={5}>
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <Typography
                className={classes.quoteText}
                variant='h1'></Typography>
              <div className={classes.person}>
                <Typography
                  className={classes.name}
                  variant='body1'></Typography>
                <Typography
                  className={classes.bio}
                  variant='body2'></Typography>
              </div>
            </div>
          </div>
        </Grid>
        <Grid className={classes.content} item lg={7} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentHeader}></div>
            <div className={classes.contentBody}>
              <form className={classes.form} onSubmit={handleSignIn}>
                <Typography className={classes.title} variant='h2'>
                  Sign in
                </Typography>
                <TextField
                  className={classes.textField}
                  error={hasError('email')}
                  fullWidth
                  helperText={
                    hasError('email') ? formState.errors.email[0] : null
                  }
                  label='Email address'
                  name='email'
                  onChange={handleChange}
                  type='text'
                  value={formState.values.email || ''}
                  variant='outlined'
                />
                <TextField
                  className={classes.textField}
                  error={hasError('password')}
                  fullWidth
                  helperText={
                    hasError('password') ? formState.errors.password[0] : null
                  }
                  label='Password'
                  name='password'
                  onChange={handleChange}
                  type='password'
                  value={formState.values.password || ''}
                  variant='outlined'
                />
                <Button
                  className={classes.signInButton}
                  color='primary'
                  disabled={!formState.isValid}
                  fullWidth
                  size='large'
                  type='submit'
                  variant='contained'>
                  Sign in now
                </Button>
                <Typography color='textSecondary' variant='body1'>
                  Don't have an account?{' '}
                  <Link component={RouterLink} to='/sign-up' variant='h6'>
                    Sign up
                  </Link>
                </Typography>
                <Typography color='textSecondary' variant='body1'>
                  {' '}
                  Forgot your password?{' '}
                  <Link
                    component={RouterLink}
                    to='/ForgotPassword'
                    variant='h6'>
                    Reset Password
                  </Link>
                </Typography>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(SignIn);
