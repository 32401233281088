import React from 'react';
import ButtonPrimary from 'components/UI/Buttons/ButtonPrimary';
import usePostAmazonAccount from 'api/services/amazonAccounts/post';
import Alert from '@material-ui/lab/Alert';
import getConectAccounts from 'api/services/amazonAccounts/get';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import redirectUrl from 'helpers/redirectUrl';
import useSafeFunction from 'hooks/useSafeFunction';
import { LinearProgress, Typography } from '@material-ui/core';
import { amazonAccountUrl, dataTableFields } from '../constants';
import useAuthContext from '@base/common/hooks/auth-context';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import DataTableTest from 'components/UI/DataTable/containers/dataTableTest';
import EditName from './EditName';

const AmazonAccounts: React.FC = () => {
  const { userId, changeLinkedAccountsStatus } = useAuthContext();
  const { loading, response, refetch, error } = getConectAccounts({ userId });
  const [errorMutation, setErrorMutation] = React.useState<string>('');
  const safeSetErrorMutation = useSafeFunction(setErrorMutation);
  const [successMutation, setSuccessMutation] = React.useState<string>('');
  const [loadinButton, setLoadinButton] = React.useState(false);
  const { loading: loadingMutation } = usePostAmazonAccount({
    onError(err) {
      safeSetErrorMutation(err.message);
    },
    onCompleted(data) {
      const {
        createMwsAccount: { status, message },
      } = data;
      if (status) {
        setSuccessMutation(message);
        changeLinkedAccountsStatus();
        refetch();
      } else {
        setErrorMutation(message);
      }
    },
  });

  const handleConnectAccount = () => {
    setLoadinButton(true);
    redirectUrl(amazonAccountUrl(userId));
  };

  const closeAndRedirection = () => {
    redirectUrl('/amazon-accounts');
  };

  if (error)
    return (
      <Alert data-testid='alertErrorService' severity='error'>
        {error}
      </Alert>
    );

  return (
    <>
      <div className='flex justify-between mb-10'>
        <div className='flex items-center'>
          <Typography variant='h1' component='h2' gutterBottom>
            Amazon Account List
          </Typography>
          <Tooltip
            title={
              <p className='text-base'>
                In this section you can see and link multiple Amazon Accounts.
                Also you can unlink any Amazon Account.
              </p>
            }
            placement='bottom-start'>
            <InfoIcon color='secondary' fontSize='small' className='ml-2' />
          </Tooltip>
        </div>

        {!loadingMutation && (
          <ButtonPrimary
            className='tour5'
            data-testid='connectAmazonAcountButton'
            onClick={handleConnectAccount}
            loading={loadinButton}
            loadingText={'Redirecting to amazon...'}
            id='connectAmazonAccount'
            content='Connect amazon accounts'
            startIcon={<AccountTreeIcon />}
          />
        )}
      </div>

      {loadingMutation && (
        <div
          data-testid='loadingConnectingAmazon'
          className='flex flex-col items-center mt-10'>
          Connecting Amazon Account...{' '}
          <LinearProgress className='my-5 w-full' color='primary' />
        </div>
      )}

      {/* TODO: Refactor alert component to insolated */}
      {errorMutation && (
        <Alert
          onClose={() => closeAndRedirection()}
          data-testid='alertErrorService'
          severity='error'>
          {errorMutation}
        </Alert>
      )}
      {successMutation && (
        <Alert
          onClose={() => closeAndRedirection()}
          data-testid='alertSucessService'
          severity='success'>
          {successMutation}
        </Alert>
      )}

      <div className='mt-10'>
        <DataTableTest data={response?.data as any[]} loading={loading}>
          <DataTableTest.Container>
            <DataTableTest.Headers
              headers={dataTableFields}
              sortBy={'orderId'}
            />
            <DataTableTest.body>
              <DataTableTest.Row
                dataFields={dataTableFields}
                editComponent={(field: any, id: any) => (
                  <EditName field={field} id={id} />
                )}
              />
            </DataTableTest.body>
          </DataTableTest.Container>
          <DataTableTest.DensenPadding />
        </DataTableTest>
      </div>
    </>
  );
};

export default AmazonAccounts;
