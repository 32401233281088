import { gql } from '@apollo/client';

export const GET_SKU = gql`
  query getSku(
    $userId: String!
    $reqPage: Int!
    $reqLimit: Int!
    $filters: SkuFilters!
  ) {
    skusByUser(
      userId: $userId
      reqPage: $reqPage
      reqLimit: $reqLimit
      filters: $filters
    ) {
      status
      data {
        asin
        count
        name
        fnsku
        sku
        MissingParts
        NewItem
        NewOpenBox
        Refurbished
        UsedAcceptable
        UsedGood
        UsedLikeNew
        UsedPoor
        UsedVeryGood
      }
      totalDocs
      totalPages
    }
  }
`;
