import React from 'react';
import AcceptanceList from './containers/List';
import { Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';

const AcceptanceModule: React.FC = () => {
  return (
    <div data-testid='acceptancePage' className='bg-white p-5'>
      <div className='flex items-center'>
        <Typography variant='h1' component='h2' gutterBottom>
          SKU Acceptance Progress
        </Typography>
        <Tooltip
          title={
            <p className='text-base'>
              In this section you can see the acceptance progress by sku that
              have been processed in our location in a date range provided.
            </p>
          }
          placement='bottom-start'>
          <InfoIcon color='secondary' fontSize='small' className='ml-2' />
        </Tooltip>
      </div>

      <AcceptanceList />
    </div>
  );
};

export default AcceptanceModule;
