import { ApolloError, useQuery } from '@apollo/client';
import { IActiveListingsExternal } from 'interfaces/ActiveListings';
import { GET_ACTIVE_LISTINGS } from './queries';

interface IEndpointResponse {
  loading: boolean;
  error: ApolloError | undefined;
  data: IActiveListingsExternal | undefined;
  refetch: () => void;
}

export interface IActiveListingsFilters {
  asin?: string;
  fnsku?: string;
  condition?: string;
}

export interface IActiveListingsVars {
  userId: string | null;
  reqPage: number | undefined;
  reqLimit: number | undefined;
  filters: IActiveListingsFilters;
}

export const useGetActiveListingsGraphql = ({
  userId,
  reqPage,
  reqLimit,
  filters,
}: IActiveListingsVars): IEndpointResponse => {
  const { loading, error, data, refetch } = useQuery<
    IActiveListingsExternal,
    IActiveListingsVars
  >(GET_ACTIVE_LISTINGS, {
    variables: {
      userId,
      reqPage,
      reqLimit,
      filters,
    },
  });

  return {
    loading,
    error,
    data,
    refetch,
  };
};
