import React from 'react';
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Switch,
} from '@material-ui/core';
import useAuthContext from '@base/common/hooks/auth-context';
import useGetEmailSettings from 'api/services/emailSettings/get';
import usePostEmailSettings from 'api/services/emailSettings/post';

const EmailSettings = () => {
  const { userId } = useAuthContext();

  const { response, loading, refetch } = useGetEmailSettings({
    userId,
  });

  const { settingsAddEmailNotification, data } = usePostEmailSettings();

  const [emailNotifications, setEmailNotifications] = React.useState({
    removalOrders: response?.data?.emailNotification.removalOrders,
    boxRecieved: response?.data?.emailNotification.removalOrders,
    itemSold: response?.data?.emailNotification.removalOrders,
  });

  React.useEffect(() => {
    setEmailNotifications({
      removalOrders: response?.data?.emailNotification.removalOrders || false,
      boxRecieved: response?.data?.emailNotification.boxRecieved || false,
      itemSold: response?.data?.emailNotification.itemSold || false,
    });
  }, [loading]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailNotifications({
      ...emailNotifications,
      [event.target.name]: event.target.checked,
    });
    settingsAddEmailNotification({
      variables: {
        settings: { [event.target.name]: event.target.checked },
        userId,
      },
    });
    refetch();
  };

  React.useEffect(() => {
    if (data?.status) {
      refetch();
    }
  }, [data.status]);

  return (
    <div className='m-5'>
      <FormControl component='fieldset'>
        <FormLabel component='legend'>Notifications</FormLabel>
        {loading ? (
          <CircularProgress />
        ) : (
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={emailNotifications.removalOrders}
                  onChange={handleChange}
                  name='removalOrders'
                />
              }
              label='Removal order confirmed.'
            />
            <FormControlLabel
              control={
                <Switch
                  checked={emailNotifications.boxRecieved}
                  onChange={handleChange}
                  name='boxRecieved'
                />
              }
              label='Box received.'
            />
            <FormControlLabel
              control={
                <Switch
                  checked={emailNotifications.itemSold}
                  onChange={handleChange}
                  name='itemSold'
                />
              }
              label='Item Sold.'
            />
          </FormGroup>
        )}
      </FormControl>
    </div>
  );
};

export default EmailSettings;
