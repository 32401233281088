import { gql } from '@apollo/client';

export const GET_SALES = gql`
  query getSales(
    $userId: String!
    $reqLimit: Int
    $reqPage: Int
    $filters: PurchaseFilter
  ) {
    purchaseOrdersCustomers(
      userId: $userId
      reqLimit: $reqLimit
      reqPage: $reqPage
      filters: $filters
    ) {
      page
      status
      totalDocs
      totalPages
      data {
        AmazonOrderId
        FulfillmentChannel
        InternalId
        OrderStatus
        totalGross
        OrderTotal {
          Amount
          CurrencyCode
        }
        OrderType
        PurchaseDate
        ReferralFee
        _id
        OrderItems {
          ASIN
          SellerSKU
          OrderItemId
          Title
          ReferralFee
          FBAFee
          ComissionFee
          ConditionId
          QuantityOrdered
          QuantityShipped
          ItemPrice {
            Amount
          }
        }
      }
    }
  }
`;
