import { createContext } from 'react';

export const AuthContext = createContext({
  isLoggedIn: false,
  userId: null,
  email: null,
  tenant: null,
  error: null,
  authProvider: null,
  stripeStatus: null,
  token: null,
  emailVerified: null,
  linkedAccounts: null,
  login: () => {},
  logout: () => {},
  clearError: () => {},
  updateSettings: () => {},
  changeEmailVerificationStatus: () => {},
  changeLinkedAccountsStatus: () => {},

  // TODO: change this states context to store with zustand
  showButtonRefreshOrders: null,
  setShowButtonRefreshOrders: (state) => {
    state;
  },
  showAlertRefreshOrders: null,
  setShowAlertRefreshOrders: (state) => {
    state;
  },
  mutationStatus: null,
  setMutationStatus: (state) => {
    state;
  },
  lastReportDateStatus: null,
  setLastReportDateStatus: (date) => {
    date;
  },

  buttonEditState: null,
  setButtonEditState: (state) => {
    state;
  },
});
