import React from 'react';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import Tab from '@material-ui/core/Tab';
import TabPanel from '@material-ui/lab/TabPanel';
import SalesDetails from './SalesDetails';
import PaymentsDetails from './PaymentsDetails';
import { AppBar } from '@material-ui/core';

const FinancesContainer = () => {
  const [value, setValue] = React.useState('1');

  const handleChange = (_event: any, newValue: string) => {
    setValue(newValue);
  };

  return (
    <div>
      <TabContext value={value}>
        <AppBar position='static'>
          <TabList onChange={handleChange} aria-label='simple-tabs'>
            <Tab label='Sales Details' value='1' />
            <Tab label='Payments Details' value='2' />
          </TabList>
        </AppBar>
        <TabPanel value='1'>
          <SalesDetails />
        </TabPanel>
        <TabPanel value='2'>
          <PaymentsDetails />
        </TabPanel>
      </TabContext>
    </div>
  );
};

export default FinancesContainer;
