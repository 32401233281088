import {
  IEndpointVariables,
  IResponseInternal,
  usePostEmailSettingsGraphql,
} from 'api/endpoints/emailSettings/post';
import { postEmailNotificationTransform } from 'api/transforms/EmailSettings';

export interface IServiceResponse {
  settingsAddEmailNotification: (variables: IEndpointVariables) => void;
  loading: boolean;
  data: IResponseInternal;
}

const usePostEmailSettings = (): IServiceResponse => {
  const { settingsAddEmailNotification, loading, data } =
    usePostEmailSettingsGraphql();

  return {
    settingsAddEmailNotification,
    loading,
    data: postEmailNotificationTransform(data),
  };
};

export default usePostEmailSettings;
