import {
  IFinancesPayments,
  IFinancesPaymentsInternal,
} from 'interfaces/FinancesPayments';

export interface IServiceResponseFinancesPayments {
  data: IFinancesPaymentsInternal[];
  meta: {
    totalDocs: number;
    totalPages: number;
    status: boolean;
    page: number;
  };
}

const FinancesPaymentsTranform = (
  data: IFinancesPayments,
): IServiceResponseFinancesPayments => {
  const dataTransform = data.balancePaymentsSummaryCustomers.data;
  const meta = {
    totalDocs: data.balancePaymentsSummaryCustomers.totalDocs,
    totalPages: data.balancePaymentsSummaryCustomers.totalPages,
    status: data.balancePaymentsSummaryCustomers.status,
    page: data.balancePaymentsSummaryCustomers.page,
  };

  return {
    data: dataTransform,
    meta,
  };
};

export default FinancesPaymentsTranform;
