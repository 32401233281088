import { ApolloError } from '@apollo/client';
import { useGetOrdersOffersGraphqlLazy } from 'api/endpoints/activeListings/getOrdersOffersLazy';
import { getErrorTransform } from 'api/transforms/errors';
import { getOrdersOffersTransformLazy } from 'api/transforms/ordersOffersTransformLazy';
// import { getOrdersOffersTransform } from 'api/transforms/ReceiveBox';
import { IOrdersGetOffers, Iorderstest } from 'interfaces/ActiveListings';

interface IServiceState {
  getOrdersOffers: (variables: Iorderstest) => void;
  loading: boolean;
  error: ApolloError | string | undefined;
  serviceResponse: IOrdersGetOffers | undefined;
}

const useGetOrdersOffersLazy = (): IServiceState => {
  const { getOrdersOffers, loading, error, data } =
    useGetOrdersOffersGraphqlLazy();

  if (data) {
    return {
      getOrdersOffers,
      loading,
      error,
      serviceResponse: getOrdersOffersTransformLazy(data),
    };
  } else {
    return {
      getOrdersOffers,
      loading,
      error: getErrorTransform(error),
      serviceResponse: data,
    };
  }
};

export default useGetOrdersOffersLazy;
