import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@material-ui/core';
const ErrorModal = (props) => {
  const handleClose = () => {
    props.clearError();
  };

  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth={true}
        maxWidth='sm'>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent className='text-center'>
          <ErrorOutlineIcon color='error' fontSize='large' />
          <DialogContentText className='pt-2'>{props.alert}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {props.linkSignIn ? (
            <Link component={RouterLink} to='/sign-in' variant='h6'>
              BACK TO SIGN IN
            </Link>
          ) : null}
          {props.buttonClose ? (
            <Button onClick={handleClose} color='primary' autoFocus>
              Close
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default ErrorModal;
