import useGetFinancesPayments from 'api/services/finances/getPayments';
import React from 'react';
import FilterSection from 'components/UI/filters/FilterSection';
import useAuthContext from '@base/common/hooks/auth-context';
import useOptionsPage from 'hooks/useOptionsPage';
import { DataTable } from 'components/UI/DataTable';
import {
  dataTableFields,
  dataTableRowsPerPage,
  dateFilters,
} from '../constants/index';

interface IFilterState {
  dateStart: string | '';
  dateEnd: string | '';
}

const PaymentsDetails = () => {
  const { page, setPage, rowsPerPage, setRowsPerPage } = useOptionsPage();

  const { userId } = useAuthContext();

  const [filterOptions, setFilterOptions] = React.useState<IFilterState>({
    dateStart: '',
    dateEnd: '',
  });

  const [FilterComplete, setFilterComplete] =
    React.useState<IFilterState>(filterOptions);

  const { loading, response } = useGetFinancesPayments({
    userId,
    dateStart: FilterComplete.dateStart,
    dateEnd: FilterComplete.dateEnd,
    reqPage: page,
    reqLimit: dataTableRowsPerPage,
  });

  return (
    <div>
      <FilterSection
        setFilterOptions={setFilterOptions}
        filterOptions={filterOptions}
        dateFilters={dateFilters}
        setFilterComplete={setFilterComplete}
        isValidateDateMonth
        setPage={setPage}
      />
      <DataTable
        data={response?.data as any[]}
        headers={dataTableFields}
        dataFields={dataTableFields}
        loading={loading}
        count={response?.meta.totalDocs || 0}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
    </div>
  );
};

export default PaymentsDetails;
