import { ApolloError, useQuery } from '@apollo/client';
import { IAcceptanceExternal } from 'interfaces/Acceptance';
import { GET_ACCEPTANCE } from './queries';

interface IEndpointResponse {
  loading: boolean;
  error: ApolloError | undefined;
  data: IAcceptanceExternal | undefined;
}

export interface IAcceptanceFilters {
  sku?: string;
  dateStart?: string | null | undefined;
  dateEnd?: string | null | undefined;
}

export interface IAcceptanceVars {
  userId: string | null;
  reqPage: number;
  reqLimit: number;
  filters: IAcceptanceFilters;
}

export const useGetAcceptanceGraphql = ({
  userId,
  reqPage,
  reqLimit,
  filters,
}: IAcceptanceVars): IEndpointResponse => {
  const { loading, error, data } = useQuery<
    IAcceptanceExternal,
    IAcceptanceVars
  >(GET_ACCEPTANCE, {
    variables: {
      userId,
      reqPage,
      reqLimit,
      filters,
    },
  });

  return {
    loading,
    error,
    data,
  };
};
