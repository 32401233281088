import {
  IEndpointVariables,
  IResponseInternal,
  usePostUpdateMwsAccountGraphql,
} from 'api/endpoints/amazonAccounts/postUpdateMwsAccount';
import { postUpdateMwsAccountTransform } from 'api/transforms/AmazonAccounts';

export interface IServiceResponse {
  updateMwsAccount: (variables: IEndpointVariables) => void;
  loading: boolean;
  data: IResponseInternal | undefined;
}

const usePostUpdateMwsAccount = (): IServiceResponse => {
  const { updateMwsAccount, loading, data } = usePostUpdateMwsAccountGraphql();

  return {
    updateMwsAccount,
    loading,
    data: postUpdateMwsAccountTransform(data),
  };
};

export default usePostUpdateMwsAccount;
