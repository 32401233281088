import { createSelectorHooks } from 'auto-zustand-selectors-hook';
import create from 'zustand';
import { refetchActiveListings } from './refetchActiveListings';

let store = create((set, get) => ({
  ...refetchActiveListings(set, get),
}));

store = createSelectorHooks(store);

export const useStore = store;
