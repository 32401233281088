import { ApolloError } from '@apollo/client';
import {
  IEndpointVariables,
  IResponseInternal,
  usePostUpdateProductPriceGraphql,
} from 'api/endpoints/activeListings/post';
import { usePostUpdateProductPriceTransform } from 'api/transforms/ActiveListings';
import { getErrorTransform } from 'api/transforms/errors';

export interface IServiceResponse {
  ordersActiveListingPriceParent: (variables: IEndpointVariables) => void;
  loading: boolean;
  data: IResponseInternal | undefined;
  error: ApolloError | string | undefined;
}

const usePostUpdateProductPrice = (): IServiceResponse => {
  const { ordersActiveListingPriceParent, loading, data, error } =
    usePostUpdateProductPriceGraphql();

  return {
    ordersActiveListingPriceParent,
    loading,
    data: usePostUpdateProductPriceTransform(data),
    error: getErrorTransform(error),
  };
};

export default usePostUpdateProductPrice;
