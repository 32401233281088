import { useStore } from '../store';

const useStatesActiveLinstings = () => {
  const refetchActiveListings = useStore.useRefetchActiveListings();
  const setRefetchActiveListings = useStore.useSetRefetchActiveListings();

  return {
    refetchActiveListings,
    setRefetchActiveListings,
  };
};

export default useStatesActiveLinstings;
