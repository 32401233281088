export const dataTableFields = [
  {
    field: 'paymentId',
    headerName: 'Payment ID',
    sort: false,
  },
  {
    field: 'date',
    headerName: 'Date',
    sort: false,
    isDate: true,
  },
  {
    field: 'description',
    withChip: true,
    headerName: 'Description',
    sort: false,
  },
  {
    field: 'status',
    withChip: true,
    headerName: 'Status',
    sort: false,
  },
  {
    field: 'grossAmount',
    headerName: 'Gross Amount',
    isDate: true,
    sort: false,
    isPrice: true,
  },
  {
    field: 'amazonFees',
    headerName: 'Amazon Fees',
    isButton: true,
    sort: false,
    isPrice: true,
  },
  {
    field: 'platformFee',
    headerName: 'Platform Fee',
    isButton: true,
    sort: false,
    isPrice: true,
  },
  {
    field: 'netAmount',
    headerName: 'Net Amount',
    isButton: true,
    sort: false,
    isPrice: true,
  },
];

export const month = [
  {
    label: 'January',
    value: 1,
  },
  {
    label: 'February',
    value: 2,
  },
  {
    label: 'March',
    value: 3,
  },
  {
    label: 'April',
    value: 4,
  },
  {
    label: 'May',
    value: 5,
  },
  {
    label: 'June',
    value: 6,
  },
  {
    label: 'July',
    value: 7,
  },
  {
    label: 'August',
    value: 8,
  },
  {
    label: 'September',
    value: 9,
  },
  {
    label: 'October',
    value: 10,
  },
  {
    label: 'November',
    value: 11,
  },
  {
    label: 'December',
    value: 12,
  },
];

export const years = [
  {
    label: 2018,
  },
  {
    label: 2019,
  },
  {
    label: 2020,
  },
  {
    label: 2021,
  },
  {
    label: 2022,
  },
  {
    label: 2023,
  },
];

export const dateFilters = [
  {
    id: 'dateStart',
    name: 'dateStart',
    label: 'Start Date',
    isMonth: true,
  },
  {
    id: 'dateEnd',
    name: 'dateEnd',
    label: 'End Date',
    isMonth: true,
  },
];

export const dataTableRowsPerPage = 10;
