import { useState, useEffect, useCallback } from 'react';
import Cookies from 'js-cookie';

let logOutTimer;

export const useAuth = () => {
  const [token, setToken] = useState(() => {
    const authLocal = localStorage.getItem('userData');
    return JSON.parse(authLocal)?.token || null;
  });
  const [userId, setUserId] = useState(() => {
    const authLocal = localStorage.getItem('userData');
    return JSON.parse(authLocal)?.userId || null;
  });
  const [email, setEmail] = useState(() => {
    const authLocal = localStorage.getItem('userData');
    return JSON.parse(authLocal)?.email || null;
  });
  const [tenant, setTenant] = useState(() => {
    const authLocal = localStorage.getItem('userData');
    return JSON.parse(authLocal)?.tenant || null;
  });
  const [error, setError] = useState(); // error from social
  const [emailVerified, setEmailVerified] = useState(() => {
    const authLocal = localStorage.getItem('userData');
    return JSON.parse(authLocal)?.emailVerified || null;
  });
  const [linkedAccounts, setLinkedAccounts] = useState(() => {
    const authLocal = localStorage.getItem('userData');
    return JSON.parse(authLocal)?.linkedAccounts || null;
  });
  const [authProvider, setAuthProvider] = useState(() => {
    const authLocal = localStorage.getItem('userData');
    return JSON.parse(authLocal)?.authProvider || false;
  }); //false if 3rd party
  const [stripeStatus, setStripeStatus] = useState(); //status object for Stripe.
  const [tokenExpirationDate, setTokenExpirationDate] = useState();

  const [showButtonRefreshOrders, setShowButtonRefreshOrders] = useState(true);
  const [showAlertRefreshOrders, setShowAlertRefreshOrders] = useState(false);
  const [mutationStatus, setMutationStatus] = useState(false);
  const [lastReportDateStatus, setLastReportDateStatus] = useState(null);
  const [buttonEditState, setButtonEditState] = useState(null);

  const login = useCallback(
    (
      uid,
      email,
      tenant,
      token,
      expirationDate,
      provider,
      status,
      emailVerified,
      linkedAccounts,
    ) => {
      setToken(token);
      setEmail(email);
      setTenant(tenant);
      setEmailVerified(emailVerified);
      setLinkedAccounts(linkedAccounts);
      setStripeStatus(status);
      setAuthProvider(provider);
      const tokenExpirationDate =
        expirationDate || new Date(new Date().getTime() + 1000 * 60 * 1440); //one hour timeout.
      setTokenExpirationDate(tokenExpirationDate);
      localStorage.setItem(
        'userData',
        JSON.stringify({
          userId: uid,
          email: email,
          tenant: tenant,
          token: token,
          expiration: tokenExpirationDate.toISOString(),
          authProvider: provider,
          status: status,
          emailVerified: emailVerified,
          linkedAccounts: linkedAccounts,
        }),
      ); //save to token to local storage.
      setUserId(uid);
    },
    [],
  );

  const updateSettings = useCallback((company, email, stripe) => {
    if (company) setTenant(company);
    if (email) setEmail(email);
    if (stripe) setStripeStatus(stripe);
  }, []);

  const logout = useCallback(() => {
    setToken(null);
    setTokenExpirationDate(null);
    setUserId(null);
    localStorage.removeItem('userData');
  }, [userId]);

  const clearError = useCallback((value) => {
    setError(value);
  }, []);

  const changeEmailVerificationStatus = () => {
    setEmailVerified(true);
    const authLocal = localStorage.getItem('userData');
    const userData = JSON.parse(authLocal);
    localStorage.setItem(
      'userData',
      JSON.stringify({
        ...userData,
        emailVerified: true,
      }),
    );
  };

  const changeLinkedAccountsStatus = () => {
    const authLocal = localStorage.getItem('userData');
    const userData = JSON.parse(authLocal);
    localStorage.setItem(
      'userData',
      JSON.stringify({
        ...userData,
        linkedAccounts: true,
      }),
    );
    setLinkedAccounts(true);
  };

  useEffect(() => {
    //auto login if token exists.
    let failData = Cookies.get('fail');
    if (failData) {
      //social auth returned an error that needs to be displayed
      setError(failData);
      Cookies.set('fail', 'test', {
        path: '/',
        domain: process.env.REACT_APP_COOKIEDOMAIN,
        expires: -7,
      });
    }
    let storedData = Cookies.get('auth'); //check for cookie sent by oauth providers
    if (storedData) {
      storedData = JSON.parse(storedData.replace('j:', ''));
      storedData.expiration = new Date(new Date().getTime() + 1000 * 60 * 1440); //one hour timeout
      storedData.authProvider = false;
      Cookies.set('auth', 'test', {
        path: '/',
        domain: process.env.REACT_APP_COOKIEDOMAIN,
        expires: -7,
      });
    }
    if (!storedData) storedData = JSON.parse(localStorage.getItem('userData')); // convert json to object
    if (
      storedData &&
      storedData.token &&
      new Date(storedData.expiration) > new Date()
    ) {
      login(
        storedData.userId,
        storedData.email,
        storedData.tenant,
        storedData.token,
        new Date(storedData.expiration),
        storedData.authProvider,
        storedData.status,
        storedData.emailVerified,
        storedData.linkedAccounts,
      ); //login
    }
  }, [login]); //run once after render because use callback it will only run once.

  useEffect(() => {
    if (token && tokenExpirationDate) {
      const remainingTime =
        tokenExpirationDate.getTime() - new Date().getTime();
      logOutTimer = setTimeout(logout, remainingTime); //trigger logout when we run out of time.
    } else {
      clearTimeout(logOutTimer);
    }
  }, [token, logout, tokenExpirationDate]);

  return {
    token,
    userId,
    email,
    tenant,
    error,
    emailVerified,
    linkedAccounts,
    changeEmailVerificationStatus,
    changeLinkedAccountsStatus,
    login,
    logout,
    clearError,
    updateSettings,
    authProvider,
    stripeStatus,
    showButtonRefreshOrders,
    setShowButtonRefreshOrders,
    showAlertRefreshOrders,
    setShowAlertRefreshOrders,
    mutationStatus,
    setMutationStatus,
    lastReportDateStatus,
    setLastReportDateStatus,
    buttonEditState,
    setButtonEditState,
  };
};
