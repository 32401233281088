import {
  IResponseExternal,
  IResponseInternal,
} from 'api/endpoints/activeListings/post';
import {
  IActiveListingsExternal,
  MainProduct,
} from 'interfaces/ActiveListings';
import {
  IResponseExternal as IResponseExternalChildren,
  IResponseInternal as IResponseInternalChildren,
} from 'api/endpoints/activeListings/postUpdateChildren';

export interface IServiceResponse {
  meta: {
    totalDocs: number;
    totalPages: number;
  };
  data: MainProduct[];
}

export const getActiveListingTransform = (
  data: IActiveListingsExternal,
): IServiceResponse => {
  const newData = data.ordersGetActiveListing.data.map((item) => {
    return { ...item.mainProduct, children: item.children };
  });

  const meta = {
    totalDocs: data.ordersGetActiveListing.totalDocs,
    totalPages: data.ordersGetActiveListing.totalPages,
  };

  return { meta, data: newData };
};

export const usePostUpdateProductPriceTransform = (
  data: IResponseExternal | undefined,
): IResponseInternal => {
  const status = data?.ordersActiveListingPriceParent.status;
  const message = data?.ordersActiveListingPriceParent.data;

  return { status, data: message };
};

export const usePostUpdateProductPriceChildrenTransform = (
  data: IResponseExternalChildren | undefined,
): IResponseInternalChildren => {
  const status = data?.ordersActiveListingPriceChildren.status;
  const message = data?.ordersActiveListingPriceChildren.data;

  return { status, data: message };
};
