import React from 'react';
import RoutesBase, { PrivateRoute } from '@base/common/RoutesBase';
import AmazonAccounts from 'pages/amazon-accounts';
import Sku from 'pages/sku';
import Acceptance from 'pages/acceptance';
import Sales from 'pages/sales';
import NoMatchPage from 'pages/noMatchPage';
import PaymentDetails from 'pages/paymentDetails';
import ActiveListings from 'pages/active-listings';
import { Switch } from 'react-router-dom';
import Finances from 'pages/finances';
import Detail from 'modules/removalReturns/containers/Detail';
import EmailSettings from 'pages/emailSettings';

const RemovalReturns = React.lazy(() => import('pages/removal-returns'));

const Routes: React.FC = () => {
  return (
    <RoutesBase>
      <Switch>
        <PrivateRoute exact path='/removal-orders'>
          <RemovalReturns />
        </PrivateRoute>
        <PrivateRoute exact path='/removal-orders/:id'>
          <Detail />
        </PrivateRoute>
        <PrivateRoute exact path='/amazon-accounts'>
          <AmazonAccounts />
        </PrivateRoute>
        <PrivateRoute exact path='/sku'>
          <Sku />
        </PrivateRoute>
        <PrivateRoute exact path='/sku-aceptance-progress'>
          <Acceptance />
        </PrivateRoute>
        <PrivateRoute exact path='/your-sales'>
          <Sales />
        </PrivateRoute>
        <PrivateRoute exact path='/payment-details'>
          <PaymentDetails />
        </PrivateRoute>
        <PrivateRoute exact path='/amazon-active-listings'>
          <ActiveListings />
        </PrivateRoute>
        <PrivateRoute exact path='/finances'>
          <Finances />
        </PrivateRoute>
        <PrivateRoute exact path='/email-settings'>
          <EmailSettings />
        </PrivateRoute>
        <PrivateRoute exact path='*'>
          <NoMatchPage />
        </PrivateRoute>
      </Switch>
    </RoutesBase>
  );
};

export default Routes;
