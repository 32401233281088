export const dataTableSkusFields = [
  {
    field: 'name',
    headerName: 'Name',
    sort: false,
    width: '200px',
  },
  {
    field: 'sku',
    headerName: 'SKU',
    sort: false,
  },
  {
    field: 'asin',
    headerName: 'ASIN',
    sort: false,
  },
  {
    field: 'fnsku',
    headerName: 'FNSKU',
    sort: false,
  },
  {
    field: 'MissingParts',
    headerName: 'Missing Parts',
    sort: false,
  },
  {
    field: 'NewItem',
    headerName: 'New Item',
    sort: false,
    bodyAlign: 'left',
  },
  {
    field: 'NewOpenBox',
    headerName: 'New Open Box',
    sort: false,
  },
  {
    field: 'Refurbished',
    headerName: 'Refurbished',
    sort: false,
  },
  {
    field: 'UsedAcceptable',
    headerName: 'Used Acceptable',
    sort: false,
    bodyAlign: 'left',
  },
  {
    field: 'UsedGood',
    headerName: 'Used Good',
    sort: false,
    bodyAlign: 'left',
  },
  {
    field: 'UsedLikeNew',
    headerName: 'Used Like New',
    sort: false,
    bodyAlign: 'left',
  },
  {
    field: 'UsedPoor',
    headerName: 'Used Poor',
    sort: false,
    bodyAlign: 'left',
  },
  {
    field: 'UsedVeryGood',
    headerName: 'Used Very Good',
    sort: false,
    bodyAlign: 'left',
  },
  {
    field: 'count',
    headerName: 'Total',
    sort: false,
    bodyAlign: 'left',
  },
];

export const inputsFilters = [
  { id: 'name', name: 'name', label: 'Name' },
  { id: 'sku', name: 'sku', label: 'SKU' },
  { id: 'fnsku', name: 'fnsku', label: 'FNSKU' },
  { id: 'asin', name: 'asin', label: 'ASIN' },
];

export const dataTableRowsPerPage = 25;
