import { useMutation } from '@apollo/client';
import { POST_EMAIL_NOTIFICATION } from './queries';

export interface ISettings {
  removalOrders?: boolean;
  boxRecieved?: boolean;
  itemSold?: boolean;
}

export interface IEndpointVariables {
  variables: {
    settings: ISettings;
    userId: string | null;
  };
}

export interface IResponseExternal {
  settingsAddEmailNotification: IResponseInternal;
}

export interface IResponseInternal {
  data: boolean | undefined;
  status: boolean | undefined;
}

export interface IEndpointResponse {
  settingsAddEmailNotification: (variables: IEndpointVariables) => void;
  loading: boolean;
  data: IResponseExternal | undefined;
}

export const usePostEmailSettingsGraphql = (): IEndpointResponse => {
  const [settingsAddEmailNotification, { loading, data }] = useMutation(
    POST_EMAIL_NOTIFICATION,
  );

  return {
    settingsAddEmailNotification,
    loading,
    data,
  };
};
