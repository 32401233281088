import React from 'react';
import ActiveListings from 'modules/activeListings/containers/List';
import { Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';

const ActiveListingsModule: React.FC = () => {
  return (
    <div data-testid='activeListingsPage' className='bg-white p-5'>
      <div className='flex items-center'>
        <Typography variant='h1' component='h2' gutterBottom>
          Amazon Active Listings
        </Typography>
        <Tooltip
          title={
            <p className='text-base'>
              In this section you can find your items that are currently
              available for sell on Amazon.
            </p>
          }
          placement='bottom-start'>
          <InfoIcon color='secondary' fontSize='small' className='ml-2' />
        </Tooltip>
      </div>
      <ActiveListings />
    </div>
  );
};

export default ActiveListingsModule;
