import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import { InputLabel, MenuItem, Select, Button } from '@material-ui/core';
import { month, years } from '../constants';

const FilterPeriod = ({
  stateMonth,
  setStateMonth,
  stateYear,
  setStateYear,
  onClickFilter,
}: any) => {
  return (
    <div className='border-b-2 border-gray-400 pb-12 lg:mx-12 lg: mt-8'>
      <div className='lg:flex justify-between items-center '>
        <div>
          <p>Your statement for:</p>
        </div>
        <div>
          <div className='flex'>
            <FormControl className='flex-row w-40'>
              <InputLabel id='month'>Month</InputLabel>
              <Select
                value={stateMonth}
                name='month'
                onChange={(e: any) => setStateMonth(e.target.value)}
                displayEmpty
                className='flex-1 mr-5'
                inputProps={{ 'aria-label': 'Without label' }}>
                {month.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className='flex-row w-40'>
              <InputLabel id='Year'>Year</InputLabel>
              <Select
                value={stateYear}
                name='year'
                onChange={(e: any) => setStateYear(e.target.value)}
                displayEmpty
                className='flex-1 mr-5'
                inputProps={{ 'aria-label': 'Without label' }}>
                {years.map((item, index) => (
                  <MenuItem key={index} value={item.label}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              variant='contained'
              color='primary'
              type='submit'
              onClick={onClickFilter}
              data-testid='buttonFilterFinances'>
              Filter
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterPeriod;
