import { ApolloError, useQuery } from '@apollo/client';
import { IEmailSettingsExternal } from 'interfaces/EmailSettings';
import { GET_EMAIL_SETTINGS } from './queries';

interface IEndpointResponse {
  loading: boolean;
  error: ApolloError | undefined;
  data: IEmailSettingsExternal | undefined;
  refetch: () => void;
}

export interface IEmailSettingsVars {
  userId: string | null;
}

export const useGetEmailSettingsGraphql = ({
  userId,
}: IEmailSettingsVars): IEndpointResponse => {
  const { loading, error, data, refetch } = useQuery<
    IEmailSettingsExternal,
    IEmailSettingsVars
  >(GET_EMAIL_SETTINGS, {
    variables: {
      userId,
    },
  });

  return {
    loading,
    error,
    data,
    refetch,
  };
};
