import { ApolloError, useQuery } from '@apollo/client';
import { GET_FINANCE_PAYMENTS } from './queries';
import { IFinancesPayments } from '../../../interfaces/FinancesPayments';

interface IEndpointResponse {
  loading: boolean;
  error: ApolloError | undefined;
  data: IFinancesPayments | undefined;
  refetch: () => void;
}

export interface IFinancesPaymentsVars {
  userId: string | null;
  dateStart: string;
  dateEnd: string;
  reqPage: number;
  reqLimit: number;
}

export const useGetFinancesPaymentsGraphql = ({
  userId,
  dateStart,
  dateEnd,
  reqPage,
  reqLimit,
}: IFinancesPaymentsVars): IEndpointResponse => {
  const { loading, error, data, refetch } = useQuery<
    IFinancesPayments,
    IFinancesPaymentsVars
  >(GET_FINANCE_PAYMENTS, {
    variables: {
      userId,
      dateStart,
      dateEnd,
      reqPage,
      reqLimit,
    },
  });

  return {
    loading,
    error,
    data,
    refetch,
  };
};
