import { IFinances, IFinancesInternal } from 'interfaces/Finances';

export interface IServiceResponseFinances {
  data: IFinancesInternal[];
}

const FinancesTranform = (data: IFinances): IServiceResponseFinances => {
  const dataTransform = data.financeByUserCustomers.data;

  return {
    data: dataTransform,
  };
};

export default FinancesTranform;
