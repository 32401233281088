import { ApolloError, useQuery } from '@apollo/client';
import { ISkuExternal } from 'interfaces/Sku';
import { GET_SKU } from './queries';

interface IEndPointResponse {
  loading: boolean;
  error: ApolloError | undefined;
  data: ISkuExternal | undefined;
  refetch: () => void;
}

export interface ISKUSFilters {
  sku?: string;
  asin?: string;
  name?: string;
}

export interface IEndpointVariables {
  userId: string | null;
  reqPage: number;
  reqLimit: number;
  filters: ISKUSFilters;
}

export const useGetSkuGraphql = ({
  userId,
  reqPage,
  reqLimit,
  filters,
}: IEndpointVariables): IEndPointResponse => {
  const { loading, error, data, refetch } = useQuery<
    ISkuExternal,
    IEndpointVariables
  >(GET_SKU, {
    variables: {
      userId,
      reqPage,
      reqLimit,
      filters,
    },
  });

  return {
    loading,
    error,
    data,
    refetch,
  };
};
