import { gql } from '@apollo/client';

export const GET_FINANCE = gql`
  query MyQuery($userId: String!, $month: Int!, $year: Int!) {
    financeByUserCustomers(userId: $userId, month: $month, year: $year) {
      status
      data {
        FBAFee
        platformFee
        saleFee
        salesTotal
        totalFee
        totalNet
      }
    }
  }
`;

export const GET_FINANCE_PAYMENTS = gql`
  query MyQuery(
    $userId: String!
    $dateStart: String
    $dateEnd: String
    $reqPage: Int!
    $reqLimit: Int!
  ) {
    balancePaymentsSummaryCustomers(
      userId: $userId
      dateStart: $dateStart
      dateEnd: $dateEnd
      reqPage: $reqPage
      reqLimit: $reqLimit
    ) {
      page
      status
      totalDocs
      totalPages
      data {
        date
        year
        status
        platformFee
        paymentId
        paymentAmount
        month
        netAmount
        grossAmount
        description
        amazonFees
      }
    }
  }
`;
