import React from 'react';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {
  AppBar,
  Button,
  Container,
  TextField,
  Typography,
} from '@material-ui/core';
import { backAccountFields, paymentFrecuencyOptions } from '../constants';

const PaymentDetails = () => {
  const [value, setValue] = React.useState('1');
  const [valueFrecuency, setValueFrecuency] = React.useState('1');

  const handleChangeFrecuency = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setValueFrecuency((event.target as HTMLInputElement).value);
  };

  const handleChange = (_event: any, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <div className='flex-grow mt-10'>
        <TabContext value={value}>
          <AppBar position='static'>
            <TabList onChange={handleChange} aria-label='simple-tabs'>
              <Tab label='Payment Method' value='1' />
              <Tab label='Payment Frequency' value='2' />
            </TabList>
          </AppBar>
          <TabPanel value='1'>
            <Container maxWidth='md'>
              <div data-testid='paymentMethodTab' className='flex'>
                <form action='' className='w-3/4 m-10' autoComplete='off'>
                  <Typography variant='h3' align='left'>
                    Add Bank Account
                  </Typography>
                  <div className='grid grid-flow-row grid-cols-1 lg:grid-cols-3 grid-rows-5 lg:grid-rows-3 gap-8 my-10'>
                    {backAccountFields.map((field, index) => (
                      <TextField
                        key={index}
                        id={field.id}
                        label={field.label}
                      />
                    ))}
                  </div>
                  <Button
                    color='primary'
                    size='large'
                    type='submit'
                    variant='contained'>
                    Add Account
                  </Button>
                </form>
              </div>
            </Container>
          </TabPanel>
          <TabPanel value='2'>
            <div data-testid='paymentFrecuencyTab'>
              <FormControl
                component='fieldset'
                data-testid='radioButtonFrecuency'>
                <FormLabel component='legend'>
                  Select how often you want to receive payment
                </FormLabel>
                <RadioGroup
                  aria-label='frecuency'
                  name='frencuency'
                  value={valueFrecuency}
                  onChange={handleChangeFrecuency}>
                  {paymentFrecuencyOptions.map((option, index) => (
                    <FormControlLabel
                      key={index}
                      value={option.value}
                      control={<Radio />}
                      label={option.label}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </div>
          </TabPanel>
        </TabContext>
      </div>
    </>
  );
};

export default PaymentDetails;
