import React from 'react';
import SalesList from './containers/list';
import { Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';

const SalesModule = () => {
  return (
    <div data-testid='salesPage' className='bg-white p-5'>
      <div className='flex items-center'>
        <Typography variant='h1' component='h2' gutterBottom>
          Your Sales
        </Typography>
        <Tooltip
          title={
            <p className='text-base'>
              In this section you can find all the sales that have been made
              throught our sales channels.
            </p>
          }
          placement='bottom-start'>
          <InfoIcon color='secondary' fontSize='small' className='ml-2' />
        </Tooltip>
      </div>
      <SalesList />
    </div>
  );
};

export default SalesModule;
