import React, { useContext } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer, Typography } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import InputIcon from '@material-ui/icons/Input';
import BallotIcon from '@material-ui/icons/Ballot';
import BusinessIcon from '@material-ui/icons/Business';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import DescriptionIcon from '@material-ui/icons/Description';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import LineWeightIcon from '@material-ui/icons/LineWeight';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import MenuIcon from '@material-ui/icons/Menu';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import EmailIcon from '@material-ui/icons/Email';
import SidebarNav from './SidebarNav';
import { sidebarViewStatus } from '@base/common/context/SidebarViewStatusContext';

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)',
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingLeft: theme.spacing(2.4),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
  tenant: {
    marginLeft: theme.spacing(0),
    marginTop: theme.spacing(2),
  },
}));

const Sidebar = (props) => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  const { openSidebarDynamic, setOpenSidebarDynamic, controlSidebar } =
    useContext(sidebarViewStatus);

  const pages = [
    {
      className: 'tourFirst',
      title: 'Dashboard',
      href: '/dashboard',
      icon: <DashboardIcon />,
    },
    {
      title: 'Account',
      href: '/account',
      icon: <AccountBoxIcon />,
    },
    {
      title: 'SKU Acceptance Progress',
      href: '/sku-aceptance-progress',
      icon: <AcUnitIcon />,
    },
    {
      className: 'tour5',
      id: 'removalReturnsLink',
      title: 'Removal Orders',
      href: '/removal-orders',
      icon: <FileCopyIcon />,
    },
    {
      title: 'Amazon Active Listings',
      href: '/amazon-active-listings',
      icon: <BallotIcon />,
    },
    {
      title: 'Your Sales',
      href: '/your-sales',
      icon: <LocalMallIcon />,
    },
    {
      title: "SKU's",
      href: '/sku',
      icon: <LineWeightIcon />,
    },
    {
      title: 'Customer returns',
      href: '/Customer',
      icon: <DescriptionIcon />,
    },
    {
      title: 'Payment details',
      href: '/payment-details',
      icon: <CreditCardIcon />,
    },
    {
      title: 'Finances',
      href: '/finances',
      icon: <MonetizationOnIcon />,
    },
    {
      className: 'tour4',
      title: 'Connect Amazon Account',
      title: 'Connect Amazon Accounts',
      href: '/amazon-accounts',
      icon: <BusinessIcon />,
    },
    {
      title: 'Email Settings',
      href: '/email-settings',
      icon: <EmailIcon />,
    },
    {
      className: 'tourFinish',
      title: 'Logout',
      href: '/sign-in',
      logout: true,
      icon: <InputIcon />,
    },
    /* {
          title: 'Settings',
          href: '/settings',
          icon: <SettingsIcon />
        }*/
  ];

  return (
    <Drawer
      anchor='left'
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}>
      {
        <div
          {...rest}
          className={clsx(
            `${classes.root} ${className} ${openSidebarDynamic && 'w-52'}`,
          )}>
          <div className='flex justify-between p-2 align-middle'>
            {openSidebarDynamic && (
              <Typography align='left' variant='h5' className={classes.tenant}>
                {props.tenantname}
              </Typography>
            )}
            <MenuIcon
              onClick={controlSidebar}
              className={`cursor-pointer mt-3 ${!openSidebarDynamic && 'ml-1'}`}
            />
          </div>
          <Divider className={classes.divider} />
          <SidebarNav
            className={classes.nav}
            pages={pages}
            stateSidebar={openSidebarDynamic}
          />
        </div>
      }
    </Drawer>
  );
};

export default Sidebar;
