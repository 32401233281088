import tw, { styled } from 'twin.macro';
import ButtonMaterial from '@material-ui/core/Button';

export const ButtonEdit = styled(ButtonMaterial)`
  && {
    ${tw`ml-3 mr-3`};
  }

  &:focus {
    ${tw`outline-none`};
  }

  &:disabled {
    background-color: #e5e5e5;
    color: #666666;
  }
`;

export const UnlinkButton = styled(ButtonMaterial)`
  && {
    ${tw`mr-3 text-white bg-red-500 hover:bg-red-600`};
  }

  &:focus {
    ${tw`outline-none`};
  }

  &:disabled {
    background-color: #e5e5e5;
    color: #666666;
  }
`;
