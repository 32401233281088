import React from 'react';
import NoMatch from '../components/UI/404/NoMatch';

const NoMatchPage = () => {
  return (
    <div className='bg-white p-5 h-5/6'>
      <NoMatch />
    </div>
  );
};

export default NoMatchPage;
