import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import EnhancedTableHead, { Order } from '../components/EnhancedTableHead';
import {
  FormControlLabel,
  LinearProgress,
  Paper,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from '@material-ui/core';
import RowCollapse from '../components/RowCollapse';
import NoData from '../components/NoData';
import Row from '../components/Row';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }),
);

export interface IDataTableFields {
  field: string;
  headerName: string;
  numeric?: boolean;
  sort?: boolean;
}

export interface IPaginationState {
  paginationState: {
    page: number;
    rowsPerPage: number;
  };
}

export interface IDataTableProps {
  data: any[];
  headers: IDataTableFields[];
  dataFields: IDataTableFields[];
  dataFieldsCollapse?: IDataTableFields[];
  loading?: boolean;
  page: number;
  setPage: (page: number) => void;
  rowsPerPage: number;
  setRowsPerPage: (rowsPerPage: number) => void;
  count: number;
  isCollapse?: boolean;
  showPagination?: boolean;
  setVariablesPost?: any;
  buttonState?: boolean;
  loadingMutation?: boolean;
  dataProperty?: string;
  filterComplete?: any;
}

const DataTable: React.FC<IDataTableProps> = ({
  data,
  headers,
  dataFields,
  dataFieldsCollapse,
  loading,
  count,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  isCollapse = false,
  showPagination = true,
  setVariablesPost,
  buttonState,
  loadingMutation,
  dataProperty,
  filterComplete,
}) => {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<any>('orderId');
  const [dense, setDense] = React.useState(false);

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            aria-label='collapsible table'
            size={dense ? 'small' : 'medium'}>
            <EnhancedTableHead
              headCells={headers}
              classes={classes}
              order={order}
              orderBy={orderBy as string}
              onRequestSort={handleRequestSort}
            />

            <TableBody data-testid='dataTableBody'>
              {isCollapse ? (
                data?.map((row: any, index) => (
                  <RowCollapse
                    key={index}
                    row={row}
                    indexRow={index}
                    setVariablesPost={setVariablesPost}
                    buttonState={buttonState}
                    loadingMutation={loadingMutation}
                    options={dataFields as any[]}
                    optionsCollapse={dataFieldsCollapse}
                    dataCollapse={
                      dataProperty === 'removalOrders'
                        ? row.items
                        : dataProperty === 'sales'
                        ? row.OrderItems
                        : dataProperty === 'products'
                        ? row.products
                        : dataProperty === 'children'
                        ? row.children
                        : null
                    }
                    titleProperty={
                      dataProperty === 'removalOrders'
                        ? `Items - Order ID ${row.orderId}`
                        : dataProperty === 'sales'
                        ? `Items - Order ID ${row.AmazonOrderId}`
                        : 'Items'
                    }
                    page={page}
                    rowsPerPage={rowsPerPage}
                    filterComplete={filterComplete}
                  />
                ))
              ) : (
                <Row rows={data} options={dataFields as any[]} />
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!loading && data?.length === 0 && (
          <NoData message='No results found' />
        )}
        {loading && (
          <div data-testid='loadingDataTableStatus' className='w-full'>
            <LinearProgress
              color='primary'
              aria-label='Loading removal returns'
            />
          </div>
        )}
        {!loading && data?.length !== 0 && showPagination && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
      {!loading && data?.length !== 0 && (
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label='Dense padding'
        />
      )}
    </div>
  );
};

export default DataTable;
