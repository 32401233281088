import { ApolloError } from '@apollo/client';
import {
  IFinancesVars,
  useGetFinancesGraphql,
} from 'api/endpoints/finances/get';
import FinancesTranform, {
  IServiceResponseFinances,
} from 'api/transforms/finances';
import { getErrorTransform } from '../../transforms/errors';

interface IServiceState {
  loading: boolean;
  error: ApolloError | undefined | string;
  response: IServiceResponseFinances | undefined;
  refetch: () => void;
}

const useGetFinances = (endpointParams: IFinancesVars): IServiceState => {
  const { loading, error, data, refetch } =
    useGetFinancesGraphql(endpointParams);

  if (data) {
    return {
      loading,
      error,
      response: FinancesTranform(data),
      refetch,
    };
  } else {
    return {
      loading,
      error: getErrorTransform(error),
      response: data,
      refetch,
    };
  }
};

export default useGetFinances;
