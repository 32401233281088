export const itemsHeaders = [
  {
    field: 'asin',
    headerName: 'ASIN',
  },
  {
    field: 'fnsku',
    headerName: 'FNSKU',
  },
  {
    field: 'itemName',
    headerName: 'Item Name',
  },
  {
    field: 'sku',
    headerName: 'SKU',
  },
  {
    field: 'inprocessQty',
    headerName: 'In-Process Qty',
  },
  {
    field: 'shippedQty',
    headerName: 'Shipped Qty',
  },
  {
    field: 'receivedQty',
    headerName: 'Received Qty',
  },
  {
    field: 'rejectedQty',
    headerName: 'Rejected Qty',
  },
];

export const dataHeadersBoxDetails = [
  {
    field: 'tracking',
    headerName: 'Tracking',
    sort: false,
  },
  {
    field: 'boxStatus',
    headerName: 'Box Status',
    sort: false,
    isStatusBox: true,
  },
  {
    field: 'boxPhotos',
    headerName: 'Box Photos',
    sort: false,
    isPhoto: true,
  },
];

export const dataHeadersDetail = [
  {
    field: 'items',
    headerName: 'Products',
    sort: false,
  },
  ...dataHeadersBoxDetails,
];

export const dataBoxDetailsCollapse = [
  {
    field: 'fnsku',
    headerName: 'FNSKU',
    sort: false,
  },
  {
    field: 'asin',
    headerName: 'ASIN',
    sort: false,
  },
  {
    field: 'name',
    headerName: 'Name',
    sort: false,
  },
  {
    field: 'internalSku',
    headerName: 'Internal SKU',
    sort: false,
  },
  {
    field: 'receivingStatus',
    headerName: 'Receiving Status',
    sort: false,
  },
  {
    field: 'condition',
    headerName: 'Condition',
    sort: false,
    isCondition: true,
  },
  {
    field: 'score',
    headerName: 'Score',
    sort: false,
  },
  {
    field: 'productPhotos',
    headerName: 'Product Photos',
    sort: false,
  },
];

export const dataCollapseDetails = 'products';
export const titleCollapseDetails = 'products';
