import { gql } from '@apollo/client';

export const GET_ACCEPTANCE = gql`
  query getAcceptance(
    $userId: String!
    $reqPage: Int!
    $reqLimit: Int!
    $filters: AcceptanceFilter!
  ) {
    acceptanceCustomers(
      userId: $userId
      reqLimit: $reqLimit
      reqPage: $reqPage
      filters: $filters
    ) {
      data {
        sku
        name
        asin
        fnsku
        receivedQty
        pendingQty
        shippedQty
        rejectedQty
      }
      totalPages
      totalDocs
      status
      page
    }
  }
`;
