export const backAccountFields = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'email',
    label: 'Email',
  },
  {
    id: 'address',
    label: 'Address',
  },
  {
    id: 'phone',
    label: 'Phone',
  },
  {
    id: 'zipCode',
    label: 'Zip Code',
  },
  {
    id: 'state',
    label: 'State',
  },
  {
    id: 'city',
    label: 'City',
  },
  {
    id: 'routingNumber',
    label: 'Routing Number',
  },
  {
    id: 'bankAccountNumbers',
    label: 'Bank Account Number',
  },
];

export const paymentFrecuencyOptions = [
  {
    value: '1',
    label: '1 Month',
  },
];
