import { ApolloError, useLazyQuery } from '@apollo/client';
import {
  IGetOrdersOffersData,
  IOrdersOffersVars,
  Iorderstest,
} from 'interfaces/ActiveListings';
import { GET_ORDERS_OFFERS } from './queries';

interface IEndpointResponse {
  getOrdersOffers: (variables: Iorderstest) => void;
  loading: boolean;
  error: ApolloError | undefined;
  data: IGetOrdersOffersData | undefined;
}

export const useGetOrdersOffersGraphqlLazy = (): IEndpointResponse => {
  const [getOrdersOffers, { loading, error, data }] = useLazyQuery<
    IGetOrdersOffersData,
    IOrdersOffersVars
  >(GET_ORDERS_OFFERS);

  return {
    getOrdersOffers,
    loading,
    error,
    data,
  };
};
