import tw, { styled } from 'twin.macro';
import ButtonMaterial from '@material-ui/core/Button';

export const ButtomPrimary = styled(ButtonMaterial)`
  && {
    ${tw`py-2 px-7 rounded-md text-base font-semibold`};
  }

  &:focus {
    ${tw`outline-none`};
  }

  &:disabled {
    background-color: #e5e5e5;
    color: #666666;
  }
`;

export const ItemNameColumnWidth = styled.span`
  ${tw`flex w-52`};
`;
