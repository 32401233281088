export const dataTableHeaders = [
  {
    field: 'sku',
    headerName: "item's SKU",
    sort: true,
  },
  {
    field: 'name',
    headerName: "item's Name",
    sort: true,
  },
  {
    field: 'asin',
    headerName: 'ASIN',
    sort: true,
  },
  {
    field: 'fnsku',
    headerName: 'FNSKU',
    sort: true,
  },
  {
    field: 'receivedQty',
    headerName: 'Received Qty',
    sort: false,
    ColumnTooltip: 'Units already received in our warehouse.',
  },
  {
    field: 'pendingQty',
    headerName: 'Pending Qty',
    sort: false,
    ColumnTooltip: 'Units still being processed by Amazon.',
  },
  {
    field: 'shippedQty',
    headerName: 'Shipped Qty',
    sort: false,
    ColumnTooltip: 'Units shipped by Amazon.',
  },
  {
    field: 'rejectedQty',
    headerName: 'Rejected Qty',
    sort: false,
    ColumnTooltip: 'Units rejected at our location by any reason.',
  },
];

export const itemFilters = [
  { id: 'sku', name: 'sku', label: 'SKU' },
  { id: 'fnsku', name: 'fnsku', label: 'FNSKU' },
  { id: 'asin', name: 'asin', label: 'ASIN' },
];

export const dateFilters = [
  {
    id: 'dateStart',
    name: 'dateStart',
    label: 'Start Date',
  },
  {
    id: 'dateEnd',
    name: 'dateEnd',
    label: 'End Date',
  },
];

export const dataTableRowsPerPage = 25;
