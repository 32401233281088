import {
  IResponseExternal,
  IResponseInternal,
} from 'api/endpoints/amazonAccounts/postUpdateMwsAccount';
import {
  IAmazonAccountData,
  IAmazonAccountsExternal,
} from 'interfaces/AmazonAccounts';

export interface IServiceResponseAmazon {
  data: IAmazonAccountData[];
}

const AmazonAccountsTranform = (
  data: IAmazonAccountsExternal,
): IServiceResponseAmazon => {
  const transformData = data.accountsByUser.data;

  return { data: transformData };
};

export const postUpdateMwsAccountTransform = (
  data: IResponseExternal | undefined,
): IResponseInternal => {
  const status = data?.updateMwsAccount.status;
  const UpdateData = data?.updateMwsAccount.data;

  return { status, data: UpdateData };
};

export default AmazonAccountsTranform;
