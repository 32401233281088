import { ApolloError } from '@apollo/client';
import { getErrorTransform } from 'api/transforms/errors';
import { getSalesTransform, IServiceResponse } from 'api/transforms/sales';
import { ISalesVars, useGetSalesGraphql } from '../../endpoints/sales/get';

interface IServiceState {
  loading: boolean;
  error: ApolloError | string | undefined;
  response: IServiceResponse | undefined;
}

const useGetSales = (endpointParams: ISalesVars): IServiceState => {
  const { loading, error, data } = useGetSalesGraphql(endpointParams);

  if (data) {
    return {
      loading,
      error,
      response: getSalesTransform(data),
    };
  } else {
    return {
      loading,
      error: getErrorTransform(error),
      response: data,
    };
  }
};

export default useGetSales;
