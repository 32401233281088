import {
  MutationHookOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client';
import { POST_AMAZON_ACCOUNT } from './queries';

export interface IEndpointVariables {
  variables: {
    mwsSellerId: string;
    spapiOauthCode: string;
    user: string;
  };
}

interface IEndpointResponse {
  postAmazonAccount: (variables: IEndpointVariables) => void;
  loading: boolean;
}

export const usePostAmazonAccountGraphql = (
  options: MutationHookOptions<any, OperationVariables> | undefined,
): IEndpointResponse => {
  const [postAmazonAccount, { loading }] = useMutation(
    POST_AMAZON_ACCOUNT,
    options,
  );

  return {
    postAmazonAccount,
    loading,
  };
};
