import React, { ReactNode } from 'react';
import * as Styles from 'styles/dashboard';
import { CircularProgress } from '@material-ui/core';

export interface IButtonPrimary {
  id: string;
  loading?: boolean;
  loadingText?: string;
  disabled?: boolean;
  content: ReactNode | string;
  [x: string]: any;
}

const ButtonPrimary: React.FC<IButtonPrimary> = ({
  id,
  loading = false,
  loadingText = 'Loading',
  disabled = false,
  content,
  ...rest
}) => {
  return (
    <Styles.ButtomPrimary
      data-testid={id}
      disabled={loading || disabled}
      type='submit'
      variant='contained'
      color='primary'
      {...rest}>
      {loading ? (
        <>
          <CircularProgress size={20} className='mr-3' color='primary' />
          {loadingText}
        </>
      ) : (
        content
      )}
    </Styles.ButtomPrimary>
  );
};

export default ButtonPrimary;
