import { Typography } from '@material-ui/core';
import React from 'react';
import SkuList from './containers/List';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';

const SkuModule: React.FC = () => {
  return (
    <div data-testid='SkuPage' className='bg-white p-5'>
      <div className='flex items-center'>
        <Typography variant='h1' component='h2' gutterBottom>
          SKU’s List
        </Typography>
        <Tooltip
          title={
            <p className='text-base'>
              Here you can see all the items sent to our warehouse and their
              respective conditions in which they were received.
            </p>
          }
          placement='bottom-start'>
          <InfoIcon color='secondary' fontSize='small' className='ml-2' />
        </Tooltip>
      </div>
      <SkuList />
    </div>
  );
};

export default SkuModule;
