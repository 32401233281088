const useFormatDate = ({ dateMonthFilters, dateYearFilters }: any) => {
  const firstDay = new Date(dateYearFilters, dateMonthFilters - 1, 1);
  const lastDay = new Date(dateYearFilters, dateMonthFilters, 0);

  const dateFirstDay = () => {
    const dateToFormat = new Date(firstDay);

    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).format(dateToFormat);
  };

  const dateLastDay = () => {
    const dateToFormat = new Date(lastDay);

    return new Intl.DateTimeFormat('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    }).format(dateToFormat);
  };

  const dateParams = {
    firstDay: dateFirstDay(),
    lastDay: dateLastDay(),
    filterOn: true,
    transformDate: true,
  };

  return {
    dateParams,
  };
};

export default useFormatDate;
