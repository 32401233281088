export const dataTableFields = [
  {
    field: 'AmazonOrderId',
    headerName: 'Order ID',
    sort: false,
  },
  {
    field: 'PurchaseDate',
    headerName: 'Purcharse Date',
    sort: false,
    isDate: true,
  },
  {
    field: 'OrderStatus',
    withChip: true,
    headerName: 'Order Status',
    sort: false,
    textTooltip: {
      pending: 'Status the order Pending.',
      shipped: 'All the items of the order have been shipped.',
      canceled: 'Status the order canceled.',
      completed: 'Status the order done.',
      received: 'Status the order received.',
    },
  },
  {
    field: 'totalGross',
    headerName: 'Grand Total',
    sort: false,
    isPrice: true,
  },
  {
    field: 'FulfillmentChannel',
    headerName: 'Channel',
    sort: false,
  },
];
export const dataFieldsCollapseSales = [
  {
    field: 'ASIN',
    headerName: 'ASIN',
  },
  {
    field: 'SellerSKU',
    headerName: 'SKU',
  },
  {
    field: 'OrderItemId',
    headerName: 'Item ID',
  },
  {
    field: 'Title',
    headerName: 'Title',
  },
  {
    field: 'ItemPrice',
    headerName: 'Item Price',
    isPrice: true,
    ColumnTooltip: 'The price of the item that was sold on Amazon',
  },
  {
    field: 'ReferralFee',
    headerName: 'Referral Fee',
    isPrice: true,
    ColumnTooltip: 'Amazon Selling Fee',
  },
  {
    field: 'FBAFee',
    headerName: 'FBA Fee',
    isPrice: true,
    ColumnTooltip: 'Summary of Amazon FBA fees',
  },
  {
    field: 'ComissionFee',
    headerName: 'Platform Fee',
    isPrice: true,
    ColumnTooltip: 'The platform fee charged',
  },
  {
    field: 'QuantityOrdered',
    headerName: 'Ordered Qty',
  },
  {
    field: 'QuantityShipped',
    headerName: 'Shipped Qty',
  },
];
export const dataHeaders = [
  {
    field: 'items',
    headerName: 'Items',
    sort: false,
  },
  ...dataTableFields,
];

export const inputsFilters = [
  { id: 'AmazonOrderId', name: 'AmazonOrderId', label: 'Order ID' },
  { id: 'FulfillmentChannel', name: 'FulfillmentChannel', label: 'Channel' },
  { id: 'InternalId', name: 'InternalId', label: 'Internal Id' },
];
export const selectFilters = [
  {
    name: 'OrderStatus',
    menuItem: [
      {
        value: '',
        labelName: 'Status',
        disabled: true,
      },
      {
        value: 'pending',
        labelName: 'Pending',
      },
      {
        value: 'shipped',
        labelName: 'Shipped',
      },
      {
        value: 'received',
        labelName: 'Received',
        disabled: false,
      },
      {
        value: 'canceled',
        labelName: 'Canceled',
      },
      {
        value: 'refunded',
        labelName: 'Refunded',
      },
    ],
  },
];

export const dateFilters = [
  {
    id: 'dateStart',
    name: 'dateStart',
    label: 'Start Date',
  },
  {
    id: 'dateEnd',
    name: 'dateEnd',
    label: 'End Date',
  },
];

export const dataCollapseSales = 'OrderItems';
export const titleCollapseSales = 'Items- Order ID';
export const propertyTitleSales = 'orderId';

export const dataTableRowsPerPage = 10;
