import { gql } from '@apollo/client';

export const GET_ORDERS = gql`
  query getOrders(
    $userId: String!
    $reqPage: Int!
    $reqLimit: Int!
    $filters: OrdersFilter!
  ) {
    ordersByUser(
      userId: $userId
      reqPage: $reqPage
      reqLimit: $reqLimit
      filters: $filters
    ) {
      page
      status
      totalDocs
      totalPages
      data {
        _id
        orderId
        orderStatus
        internalStatus
        items {
          fnsku
          itemName
          sku
          trackingNumber
          shippedQty
          inprocessQty
          requestedQty
          cancelledQty
          carrier
          asin
          _id
        }
        createdAt
        toProcess
      }
    }
  }
`;

export const GET_LAST_REPORT_DATE = gql`
  query getLastReportDate($userId: String!) {
    reportsGetLastUpdated(userId: $userId) {
      data {
        orders
      }
      status
    }
  }
`;

export const GET_ORDER_DETAIL = gql`
  query OrderDetail($orderId: String!, $userId: String!) {
    ordersCustomersDetail(orderId: $orderId, userId: $userId) {
      status
      data {
        received {
          products {
            receivingStatus
            name
            asin
            _id
            condition
            fnsku
            internalSku
            productPhotos
            score
          }
          _id
          boxPhotos
          boxStatus
          tracking
        }
        _id
        orderId
        orderStatus
        internalStatus
        createdAt
        itemsQty
        customerEmail
        customerFirstName
        customerLastName
        mwsSellerId
        company
        items {
          _id
          asin
          fnsku
          inprocessQty
          itemName
          receivedQty
          rejectedQty
          shippedQty
          sku
        }
      }
    }
  }
`;

export const POST_ORDERS = gql`
  mutation MyMutation($orderId: String!, $process: String!) {
    orderMarkToProcess(orderId: $orderId, process: $process) {
      status
    }
  }
`;

export const POST_REFRESH_ORDERS = gql`
  mutation postRefreshOrders($userId: String!) {
    orderRefreshTicket(userId: $userId) {
      data
      status
    }
  }
`;

export const GET_ACCOUNT_REMOVAL = gql`
  query MyQuery($userId: String!) {
    accountsByUser(userId: $userId) {
      status
      data {
        _id
        mwsName
      }
    }
  }
`;
