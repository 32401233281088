const useFormatDateInput = (date: any) => {
  if (date !== undefined) {
    const modifier = date.split('/');

    const format = `${modifier[2]}-${modifier[0]}-${modifier[1]}`;

    return format;
  }

  return date;
};

export default useFormatDateInput;
