import React from 'react';
import ClearIcon from '@material-ui/icons/Clear';
import validateAndProcessDates from './validateAndProcessDates';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Button,
  Collapse,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@material-ui/core';
import { useStylesContainer } from 'modules/acceptance/containers/List';
import useFormatDateInput from 'modules/sales/hooks/useFormatDateInput';
import toast from 'react-hot-toast';

export interface IFilterProps {
  filterOptions: any;
  setFilterOptions: any;
  inputsFilters?: any;
  selectFilters?: any;
  isSelect?: boolean;
  dateFilters?: any;
  setFilterComplete?: any;
  isValidateDate?: boolean;
  showFilters?: boolean;
  isTrasformDate?: boolean;
  isValidateDateMonth?: boolean;
  multipleAutoComplete?: any;
  validateField?: string[];
  setPage: (value: number) => void;
}

const FilterSection: React.FC<IFilterProps> = ({
  filterOptions,
  setFilterOptions,
  inputsFilters,
  selectFilters,
  dateFilters,
  setFilterComplete,
  isValidateDate,
  showFilters,
  isTrasformDate,
  isValidateDateMonth,
  multipleAutoComplete,
  validateField,
  setPage,
}) => {
  const classes = useStylesContainer();

  const [checked, setChecked] = React.useState(showFilters ? true : false);
  const [modifyIsTransformDate, setModifyIsTransformDate] =
    React.useState(isTrasformDate);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const onSubmit = (e: any) => {
    e.preventDefault();

    // TODO: review this conditions
    if (!isValidateDate && isValidateDateMonth) {
      if (
        (isValidateDateMonth &&
          filterOptions.dateStart <= filterOptions.dateEnd) ||
        (isValidateDateMonth &&
          filterOptions.dateStart !== '' &&
          filterOptions.dateEnd === '') ||
        (isValidateDateMonth &&
          filterOptions.dateStart === '' &&
          filterOptions.dateEnd === '')
      ) {
        setFilterComplete({
          ...filterOptions,
          dateStart: filterOptions.dateStart,
          dateEnd: filterOptions.dateEnd,
        });
      } else {
        toast.error('End date should be greater than the Start date');
      }
    }

    if (!isValidateDateMonth) {
      if (isValidateDate) {
        validateAndProcessDates(filterOptions, setFilterComplete);
      } else {
        setFilterComplete(filterOptions);
      }
    }
    setPage(0);
  };

  return (
    <div className={classes.rootSwitch}>
      <FormControlLabel
        control={<Switch checked={checked} onChange={handleChange} />}
        label='Filters'
        data-testid='switchFilters'
      />
      {checked && (
        <>
          <div className={classes.container}>
            <Collapse in={checked}>
              <form
                onSubmit={onSubmit}
                className={`${classes.root} flex flex-wrap flex-col md:mb-16 sm:flex-row`}
                noValidate
                autoComplete='off'>
                {inputsFilters?.map((input: any) => (
                  <div className='flex' key={input.id}>
                    <TextField
                      data-testid={input.name}
                      value={filterOptions[input.name]}
                      id={input.id}
                      name={input.name}
                      label={input.label}
                      onChange={(e) => {
                        if (input.isMultipleValueInput) {
                          const trackingsArray = e.target.value.split(',');

                          setFilterOptions({
                            ...filterOptions,
                            [e.target.name]: trackingsArray,
                          });
                        } else {
                          setFilterOptions({
                            ...filterOptions,
                            [e.target.name]: e.target.value.trim(),
                          });
                        }
                      }}
                    />

                    {filterOptions[input.name] !== '' &&
                      filterOptions[input.name].length > 0 && (
                        <ClearIcon
                          data-testid='bottonClearInput'
                          onClick={() =>
                            setFilterOptions({
                              ...filterOptions,
                              [input.name]: input.isMultipleValueInput
                                ? []
                                : '',
                            })
                          }
                          className='cursor-pointer'
                        />
                      )}
                  </div>
                ))}

                {selectFilters?.map((item: any) => (
                  <FormControl key={item.name} className={classes.formControl}>
                    <Select
                      value={filterOptions[item.name] || ''}
                      name={item.name}
                      disabled={
                        validateField &&
                        filterOptions[validateField[0]] === '' &&
                        filterOptions[validateField[1]] === ''
                          ? true
                          : false
                      }
                      onChange={(e: any) =>
                        setFilterOptions({
                          ...filterOptions,
                          [e.target.name]: e.target.value,
                        })
                      }
                      displayEmpty
                      className={classes.selectEmpty}
                      inputProps={{ 'aria-label': 'Without label' }}>
                      {item.menuItem.map((menuItems: any) => (
                        <MenuItem
                          key={menuItems.labelName}
                          value={menuItems.value}
                          disabled={menuItems.disabled}>
                          {menuItems.labelName}
                        </MenuItem>
                      ))}
                    </Select>
                    {filterOptions[item.name] !== '' && (
                      <ClearIcon
                        className='cursor-pointer'
                        onClick={() =>
                          setFilterOptions({
                            ...filterOptions,
                            [item.name]: '',
                          })
                        }
                      />
                    )}
                  </FormControl>
                ))}

                {dateFilters?.map((input: any) => (
                  <div className='flex' key={input.id}>
                    <TextField
                      value={
                        filterOptions[input.name] !== undefined &&
                        showFilters &&
                        modifyIsTransformDate
                          ? useFormatDateInput(filterOptions[input.name])
                          : filterOptions[input.name] || ''
                      }
                      id={input.id}
                      name={input.name}
                      label={input.label}
                      type={input.isMonth ? 'month' : 'date'}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        setModifyIsTransformDate(false);
                        setFilterOptions({
                          ...filterOptions,
                          [e.target.name]: e.target.value,
                        });
                      }}
                    />

                    {filterOptions[input.name] !== undefined &&
                      filterOptions[input.name] !== '' &&
                      filterOptions[input.name] !== null && (
                        <ClearIcon
                          onClick={() => {
                            setFilterOptions({
                              ...filterOptions,
                              [input.name]: !isValidateDateMonth
                                ? undefined
                                : '',
                            });
                          }}
                          className='cursor-pointer'
                        />
                      )}
                  </div>
                ))}

                {multipleAutoComplete ? (
                  <Autocomplete
                    multiple
                    id='tags-standard'
                    options={multipleAutoComplete}
                    getOptionLabel={(option: any) => option.mwsName}
                    onChange={(_event: any, newValue: any) => {
                      const result = newValue.map((item: any) => {
                        return item._id;
                      });
                      setFilterOptions({ ...filterOptions, account: result });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant='standard'
                        label='Accounts User'
                        placeholder='Accounts'
                      />
                    )}
                  />
                ) : null}

                <Button
                  className='h-10'
                  variant='contained'
                  color='primary'
                  type='submit'
                  data-testid='buttonFilter'>
                  Filter
                </Button>
              </form>
            </Collapse>
          </div>
        </>
      )}
    </div>
  );
};

export default FilterSection;
